<template>
    <div>
        <AppBar v-if="windowWidth <= 600" />
        <v-sheet v-if="windowWidth >= 600" class="mt-0" max-width="100%" color="#E6EBEF" style="height: 100hv">
            <v-container fluid style="max-width: 100% !important">
                <v-row>
                    <v-col cols="12" md="7">
                        <div class="text-center">
                            <p
                                class="text-uppercase"
                                style="font-family: 'Rubik'; font-style: normal; font-weight: 600; font-size: 39px; color: #0674ba; margin-top: 90px"
                            >
                                Fast, Reliable, <br />
                                Accountable <br />
                                and Secure service
                            </p>

                            <p
                                style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 12px;
                                    color: #0674ba;
                                    margin-top: 25px;
                                    letter-spacing: 0.8px;
                                "
                            >
                                Our foremost priority is to accommodate the customer’s requirements <br />
                                while maintaining integrity and accuracy consistently.
                            </p>

                            <v-img
                                lazy-src="@/assets/image/Login/login.png"
                                max-height="auto"
                                max-width="70%"
                                src="@/assets/image/Login/login.png"
                                style="margin-left: auto; margin-right: auto; display: block"
                            ></v-img>
                        </div>
                    </v-col>

                    <v-col cols="12" md="5" style="background-color: #ffffff">
                        <v-img
                            lazy-src="@/assets/image/falcon-icon.png"
                            max-height="auto"
                            max-width="28%"
                            src="@/assets/image/falcon-icon.png"
                            style="margin-left: 50px"
                        ></v-img>
                        <p
                            style="
                                font-family: 'Rubik';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 25px;
                                color: #0674ba;
                                margin-top: 20px;
                                margin-left: 50px;
                            "
                        >
                            Create your Account
                        </p>
                        <v-img
                            lazy-src="@/assets/image/Login/blue-bar.png"
                            max-height="auto"
                            max-width="6%"
                            style="background-position: center; margin-bottom: 15px; margin-left: 50px"
                            src="@/assets/image/Login/blue-bar.png"
                        ></v-img>

                        <p
                            style="
                                font-family: 'SF Pro Display';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                color: black;
                                letter-spacing: 0.8px;
                                margin-left: 50px;
                                margin-top: 10px;
                            "
                        >
                            First Name
                        </p>

                        <v-text-field
                            v-model="firstName"
                            :rules="[rules.required]"
                            dense
                            placeholder="First Name"
                            solo
                            flat
                            background-color="#F3F4F6"
                            style="margin-left: 50px; margin-right: 50px; border-radius: 10px"
                        ></v-text-field>

                        <p
                            style="
                                font-family: 'SF Pro Display';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                color: black;
                                letter-spacing: 0.8px;
                                margin-left: 50px;
                                margin-top: 10px;
                            "
                        >
                            Last Name
                        </p>

                        <v-text-field
                            v-model="lastName"
                            dense
                            placeholder="Last Name"
                            solo
                            flat
                            background-color="#F3F4F6"
                            style="margin-left: 50px; margin-right: 50px; border-radius: 10px"
                        ></v-text-field>

                        <p
                            style="
                                font-family: 'SF Pro Display';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                color: black;
                                letter-spacing: 0.8px;
                                margin-left: 50px;
                            "
                        >
                            Mobile Number
                        </p>

                        <v-text-field
                            v-model="mobileNumber"
                            :rules="[rules.required]"
                            dense
                            placeholder="eg : +94 717275539"
                            solo
                            flat
                            background-color="#F3F4F6"
                            style="margin-left: 50px; margin-right: 50px; border-radius: 10px"
                        ></v-text-field>

                        <p
                            style="
                                font-family: 'SF Pro Display';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                color: black;
                                letter-spacing: 0.8px;
                                margin-left: 50px;
                            "
                        >
                            Address
                        </p>

                        <v-text-field
                            v-model="address"
                            dense
                            placeholder="Address"
                            solo
                            flat
                            background-color="#F3F4F6"
                            style="margin-left: 50px; margin-right: 50px; border-radius: 10px"
                        ></v-text-field>

                        <p
                            style="
                                font-family: 'SF Pro Display';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                color: black;
                                margin-top: 10px;
                                letter-spacing: 0.8px;
                                margin-left: 50px;
                            "
                        >
                            Password
                        </p>

                        <v-text-field
                            v-model="password"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show2 ? 'text' : 'password'"
                            value="Abcdef@123"
                            @click:append="show2 = !show2"
                            dense
                            placeholder="Password"
                            solo
                            flat
                            background-color="#F3F4F6"
                            style="margin-left: 50px; margin-right: 50px; border-radius: 10px"
                        ></v-text-field>

                        <p
                            style="
                                font-family: 'SF Pro Display';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                color: black;
                                margin-top: 10px;
                                letter-spacing: 0.8px;
                                margin-left: 50px;
                            "
                        >
                            Confirm Password
                        </p>

                        <v-text-field
                            v-model="conPassword"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show2 ? 'text' : 'password'"
                            value="Abcdef@123"
                            @click:append="show2 = !show2"
                            dense
                            placeholder="Re-Type Password"
                            solo
                            flat
                            background-color="#F3F4F6"
                            style="margin-left: 50px; margin-right: 50px; border-radius: 10px"
                        ></v-text-field>

                        <v-row>
                            <v-col cols="12" md="12">
                                <input type="checkbox" v-model="confirm" id="termOfUse" name="termOfUse" value="Confirm" style="margin-left: 50px" />
                                <label  class="confirm-btn">
                                    &nbsp;&nbsp;By creating an account you agree to the
                                    <span class="confirm-btn terms-btn" style="color: #0674ba" @click="redirectToTermsPage()"> terms of use </span>
                                    and <br />
                                </label>
                                <label  class="confirm-btn" style="margin-left: 65px">
                                    our
                                    <span class="confirm-btn terms-btn" style="color: #0674ba" @click="redirectToPrivacyPage()"> privacy policy. </span></label
                                >
                            </v-col>
                        </v-row>
                        <v-alert style="margin-left: 50px; margin-right: 50px" type="error" dense v-if="errorAlertText != ''">
                            {{ errorAlertText }}
                        </v-alert>

                        <v-row>
                            <v-col cols="12" xl="6">
                                <v-btn
                                    color="#0674BA"
                                    :disabled="!confirm"
                                    @click="userRegister()"
                                    class="white--text rounded-lg"
                                    large
                                    style="
                                        font-family: 'Rubik-Regular';
                                        font-style: normal;
                                        font-size: 13px;
                                        font-weight: 600;
                                        margin-left: 50px;
                                        padding-left: 50px;
                                        padding-right: 50px;
                                    "
                                >
                                    Sign Up
                                </v-btn>
                                <v-btn @click="redirectHome()" large class="white--text rounded-lg cancel-btn"> Go To Home </v-btn>
                            </v-col>

                            <v-col cols="12" xl="6" class="float-right">
                                <p
                                    style="
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 12px;
                                        color: black;
                                        letter-spacing: 0.8px;
                                        margin-right: 50px;
                                        float: right;
                                        margin-top: 20px;
                                    "
                                >
                                    Already have an account?
                                    <span style="color: #0674ba; font-family: 'SF-Pro-Display-Semibold'; cursor: pointer" @click="redirectToLoginPage()">
                                        Log in
                                    </span>
                                </p>
                            </v-col>
                        </v-row>

                        <div class="text-center">
                            <p
                                style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 11px;
                                    color: black;
                                    letter-spacing: 0.8px;
                                    margin-top: 50px;
                                    padding-bottom: 50px;
                                "
                            >
                                © 2022 - Falcon Express Delivery All rights reserved.
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>

        <v-sheet v-if="windowWidth <= 600" class="mt-0" max-width="100%" color="#E6EBEF">
            <v-container fluid style="max-width: 100% !important">
                <v-row>
                    <v-col cols="12" md="12" style="background-color: #ffffff">
                        <v-card style="border-radius: 28px; padding: 20px">
                            <v-img
                                lazy-src="@/assets/image/falcon-icon.png"
                                max-height="auto"
                                max-width="200px"
                                style="margin: auto"
                                src="@/assets/image/falcon-icon.png"
                            ></v-img>
                            <p style="font-family: 'Rubik'; font-style: normal; font-weight: 600; font-size: 25px; color: #0674ba; margin-top: 20px">
                                Create your Account
                            </p>
                            <v-img
                                lazy-src="@/assets/image/Login/blue-bar.png"
                                max-height="auto"
                                max-width="18%"
                                style="background-position: center; margin-bottom: 15px"
                                src="@/assets/image/Login/blue-bar.png"
                            ></v-img>

                            <p
                                style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 14px;
                                    color: black;
                                    letter-spacing: 0.8px;
                                    margin-top: 10px;
                                "
                            >
                                First Name
                            </p>

                            <v-text-field
                                v-model="firstName"
                                :rules="[rules.required]"
                                placeholder="First Name"
                                solo
                                flat
                                background-color="#F3F4F6"
                                style="border-radius: 10px"
                            ></v-text-field>

                            <p
                                style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 14px;
                                    color: black;
                                    letter-spacing: 0.8px;
                                    margin-top: 10px;
                                "
                            >
                                Last Name
                            </p>

                            <v-text-field
                                v-model="lastName"
                                placeholder="Last Name"
                                solo
                                flat
                                background-color="#F3F4F6"
                                style="border-radius: 10px"
                            ></v-text-field>

                            <p
                                style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 14px;
                                    color: black;
                                    letter-spacing: 0.8px;
                                    margin-top: 10px;
                                "
                            >
                                Mobile Number
                            </p>

                            <v-text-field
                                v-model="mobileNumber"
                                :rules="[rules.required]"
                                placeholder="eg : +94 717275539"
                                solo
                                flat
                                background-color="#F3F4F6"
                                style="border-radius: 10px"
                            ></v-text-field>

                            <p
                                style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 14px;
                                    color: black;
                                    letter-spacing: 0.8px;
                                    margin-top: 10px;
                                "
                            >
                                Address
                            </p>

                            <v-text-field
                                v-model="address"
                                placeholder="Address"
                                solo
                                flat
                                background-color="#F3F4F6"
                                style="border-radius: 10px"
                            ></v-text-field>

                            <p
                                style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 14px;
                                    color: black;
                                    letter-spacing: 0.8px;
                                    margin-top: 10px;
                                "
                            >
                                Password
                            </p>

                            <v-text-field
                                v-model="password"
                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type="show2 ? 'text' : 'password'"
                                value="Abcdef@123"
                                @click:append="show2 = !show2"
                                placeholder="Password"
                                solo
                                flat
                                background-color="#F3F4F6"
                                style="border-radius: 10px"
                            ></v-text-field>

                            <p
                                style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 14px;
                                    color: black;
                                    letter-spacing: 0.8px;
                                    margin-top: 10px;
                                "
                            >
                                Confirm Password
                            </p>

                            <v-text-field
                                v-model="conPassword"
                                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type="show3 ? 'text' : 'password'"
                                value="Abcdef@123"
                                @click:append="show3 = !show3"
                                placeholder="Re-Type Password"
                                solo
                                flat
                                background-color="#F3F4F6"
                                style="border-radius: 10px"
                            ></v-text-field>

                            <v-row>
                                <v-col cols="auto">
                                    <input type="checkbox" v-model="confirm" value="Confirm" />

                                    <label class="confirm-btn">
                                        &nbsp;&nbsp;By creating an account you agree to the
                                        <a @click="redirectToTermsPage()" href="#" class="confirm-btn terms-btn" style="color: #0674ba"> terms of use </a>
                                        and our
                                        <a class="confirm-btn terms-btn" @click="redirectToTermsPage()" href="#" style="color: #0674ba"> privacy policy. </a></label
                                    >
                                </v-col>
                            </v-row>
                            <v-alert style="margin-left: 50px; margin-right: 50px" type="error" dense v-if="errorAlertText != ''">
                                {{ errorAlertText }}
                            </v-alert>

                            <v-row>
                                <v-col cols="12">
                                    <v-btn
                                        color="#0674BA"
                                        :disabled="!confirm"
                                        @click="userRegister()"
                                        class="white--text rounded-lg"
                                        large
                                        style="font-family: 'Rubik-Regular'; font-style: normal; font-weight: 600"
                                    >
                                        Sign Up
                                    </v-btn>
                                    <v-btn @click="redirectHome()" class="white--text rounded-lg cancel-btn" large> Go To Home </v-btn>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <div class="text-center">
                                        <p
                                            style="
                                                font-family: 'SF Pro Display';
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 14px;
                                                color: black;
                                                letter-spacing: 0.8px;
                                                margin-top: 20px;
                                            "
                                        >
                                            Already have an account?
                                            <span
                                                style="color: #0674ba; font-family: 'SF-Pro-Display-Semibold'; cursor: pointer"
                                                @click="redirectToLoginPage()"
                                            >
                                                Log in
                                            </span>
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>

                            <div class="text-center">
                                <p
                                    style="
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 13px;
                                        color: black;
                                        margin-top: 25px;
                                        letter-spacing: 0.8px;
                                        margin-bottom: 25px;
                                    "
                                >
                                    © 2022 - Falcon Express Delivery <br />
                                    All rights reserved.
                                </p>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <v-overlay :value="registerOverlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import AppBar from "./AppBar.vue";
import axios from "axios";
export default {
    name: "RegisterScreen",
    components: {
        AppBar,
    },

    data() {
        return {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            registerOverlay: false,
            mobileNumber: "",
            email: "",
            password: "",
            conPassword: "",
            firstName: "",
            lastName: "",
            corporate: "",
            address: "",
            show2: false,
            show3: false,
            confirm: false,
            errorAlertText: "",
            rules: {
                required: (value) => !!value || "Required.",
                min: (v) => v.length >= 8 || "Min 8 characters",
                emailMatch: () => `The email and password you entered don't match`,
            },
        };
    },
    mounted() {},
    created() {
        window.addEventListener("resize", this.onResize);
        console.log(this.windowHeight);
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        redirectToTermsPage() {
            this.$router.push({ name: "TermAndCondition" });
        },
        redirectToPrivacyPage() {
            this.$router.push({ name: "Privacy" });
        },
        redirectToLoginPage() {
            this.$router.push({ name: "LoginScreen" });
        },
        redirectHome() {
            this.$router.push({ name: "home" });
        },
        userRegister() {
            /* eslint-disable */
            this.registerOverlay = true;
            axios
                .post(this.$hostname + "/api/client/register", {
                    phone: this.mobileNumber,
                    password: this.password,
                    password_confirmation: this.conPassword,
                    first_name: this.firstName,
                    last_name: this.lastName,
                    address: this.address,
                })
                .then((response) => {
                    console.log(response);
                    this.$router.push({
            name: "LoginScreen",
            params: { successMessage: "User registered successfully" },
          });
                })
                .catch((error) => {
                    console.log(error);
                    this.errorAlertText = "";
                    this.errorAlertText = error.response.data.message;
                    // this.checkImportationLabReportDialog = false;
                })
                .finally(() => {
                    this.registerOverlay = false;
                });
        },
    },
};
</script>
<style scoped>
.confirm-btn {
    font-family: "SF-Pro-Display-Semibold";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: black;
    letter-spacing: 0.8px;
}

.terms-btn{
    cursor: pointer;
}

.cancel-btn {
    font-family: "Rubik-Regular";
    font-style: normal;
    background-color: rgb(26, 187, 131) !important;
    font-size: 13px;
    font-weight: 600;
    margin-left: 25px;
    padding-left: 50px;
    padding-right: 50px;
}
</style>
