<template>
  <v-app id="inspire">
    <AppBar> </AppBar>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card elevation="4">
            <v-card-title class="card__title">
              Your Packages
              <v-btn
                @click="addPackages()"
                depressed
                class="white--text"
                color="#17A2B8"
                >Add Packages</v-btn
              >
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="3" xl="3">
          <v-text-field
            label="Enter Code"
            clearable
            solo
            dense
            v-model="code"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3" xl="3">
          <v-select
            v-model="stage"
            :items="stageItems"
            label="Select Stage"
            item-text="name"
            item-value="id"
            dense
            solo
            @change="selectStage()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-text-field
            v-model="start"
            type="date"
            label="Date"
            clearable
            solo
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-text-field
            label="Date"
            type="date"
            clearable
            solo
            dense
            v-model="end"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn depressed @click="search()" class="mt-2" color="primary"
            >Search</v-btn
          >
          <v-btn
            class="ml-5 mt-2 white--text"
            depressed
            @click="clearSearch()"
            color="red"
            >Clear</v-btn
          >
         
          <v-btn   :disabled="printing" depressed @click="exportExcel" class="mt-2 ml-5" :color="printing ? '#C9C9C9':'success'"
            ><v-icon class="mr-3"> mdi-file-excel </v-icon> Export</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card elevation="2">
            <!-- MTM0MTc0MjgzNTI2MDI4MTczMDIxODk0OTc3MzU3MjM3NzQ0OTYzOA -->
            <form
              v-show="false"
              ref="paymentForm"
              method="post"
              :action="getPayHereUrl"
            >
              <input
                type="hidden"
                name="merchant_id"
                v-model="selectedPaymentItem.merchant"
              />
              <!-- Replace your Merchant ID -->
              <input
                type="hidden"
                name="hash"
                v-model="selectedPaymentItem.hash"
              />

              <input
                type="hidden"
                name="return_url"
                value="https://falcon.lk/customer/packages"
              />
              <input
                type="hidden"
                name="cancel_url"
                value="https://falcon.lk/customer/packages"
              />
              <input
                type="hidden"
                name="notify_url"
                value="https://backend.falcon.lk/payment-gateway/notify"
              />

              <input
                type="text"
                name="order_id"
                v-model="selectedPaymentItem.id"
              />
              <input
                type="text"
                name="items"
                v-model="selectedPaymentItem.code"
              />
              <input type="text" name="custom_1" value="This is custom 1" />
              <input type="text" name="currency" value="LKR" />
              <input
                type="text"
                name="amount"
                v-model="selectedPaymentItem.total_amount"
              />

              <input
                type="text"
                name="first_name"
                v-model="selectedPaymentItem.code"
              />
              <input
                type="text"
                name="last_name"
                v-model="selectedPaymentItem.code"
              />
              <input
                type="text"
                name="email"
                :value="selectedPaymentItem.sender_email"
              />
              <input
                type="text"
                name="phone"
                :value="selectedPaymentItem.sender_phone"
              />
              <input
                type="text"
                name="address"
                :value="selectedPaymentItem.pickup_city_name"
              />
              <input
                type="text"
                name="city"
                :value="selectedPaymentItem.pickup_city_name"
              />
              <input type="hidden" name="country" value="Sri Lanka" />
            </form>
            <v-data-table
              :headers="packges_headers"
              :items="getCustomerPackages"
              hide-default-footer
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip color="red" dark x-small v-if="item.status == '0'">
                  Cancelled
                </v-chip>
                <v-chip
                  color="green"
                  dark
                  x-small
                  v-else-if="item.status == '1'"
                >
                  Active
                </v-chip>
                <v-chip
                  color="yellow"
                  dark
                  x-small
                  v-else-if="item.status == '2'"
                >
                  Pending
                </v-chip>
                <v-chip color="red" dark x-small v-else-if="item.status == '3'">
                  Rejected
                </v-chip>
                <v-chip color="brown" dark x-small v-else> unknown </v-chip>
              </template>
              <template v-slot:[`item.public_status_text`]="{ item }">
                <v-chip
                  color="ash"
                  dark
                  x-small
                  v-if="item.public_status_text == 'Order Placed'"
                >
                  Order Placed
                </v-chip>
                <v-chip
                  color="blue"
                  dark
                  x-small
                  v-else-if="item.public_status_text == 'Processing'"
                >
                  Processing
                </v-chip>
                <v-chip
                  color="orange"
                  dark
                  x-small
                  v-else-if="item.public_status_text == 'Shipped'"
                >
                  Shipped
                </v-chip>
                <v-chip
                  color="green"
                  dark
                  x-small
                  v-else-if="item.public_status_text == 'Delivered'"
                >
                  Delivered
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu left bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="viewPackage(item.id)">
                      <v-list-item-title>
                        <v-icon class="mr-3">mdi-eye</v-icon>
                        View
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="
                        item.payment_type == 2 &&
                        item.pay_by == 1 &&
                        item.courier_confirm &&
                        item.total_amount > item.paid_amount
                      "
                      @click="pay(item)"
                    >
                      <v-list-item-title>
                        <v-icon class="mr-3">mdi-credit-card</v-icon>
                        Card Payment
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      @click="EditPackage(item.id)"
                      v-if="item.editable == true && item.status != 3"
                    >
                      <v-list-item-title>
                        <v-icon class="mr-3"> mdi-pencil </v-icon>
                        Edit
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="print(item)"
                      v-if="item.status != 3"
                    >
                      <v-list-item-title>
                        <v-icon class="mr-3"> mdi-cloud-print </v-icon>
                        Print
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      @click="OpendeleteDailog(item.id)"
                      v-if="item.editable == true"
                    >
                      <v-list-item-title>
                        <v-icon class="mr-3"> mdi-delete </v-icon>
                        Delete
                      </v-list-item-title>
                    </v-list-item>

                    <!-- <v-list-item
                      @click="OpendeleteDailog(item.id)"
                      v-else
                      disabled
                    >
                      <v-list-item-title>
                        <v-icon class="mr-3"> mdi-delete </v-icon>
                        Cancle
                      </v-list-item-title>
                    </v-list-item> -->
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
            <v-pagination
              v-if="getPagesCount > 1"
              class="mt-4"
              v-model="pagination.meta.current_page"
              :length="getPagesCount"
              :total-visible="7"
              @input="paginationChanged"
              circle
            ></v-pagination>
          </v-card>
        </v-col>
      </v-row>
      <!--delete modal open-->
      <v-dialog v-model="deleteModal" max-width="280px">
        <v-card>
          <v-card-title class="text-h5">Delete this order ?</v-card-title>
          <v-card-text>Package data will be deleted permanently</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteModal = false"
              >Cancel</v-btn
            >
            <v-btn color="red darken-1" text @click="deletePackage()"
              >DELETE</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--delete modal close-->
    </v-container>
    <FooterView />
  </v-app>
</template>

<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import axios from "axios";
import setAuthHeader from "@/utils/setAuthHeader";
export default {
  name: "CustomerPackages",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      getCustomerPackages: [],
      pagination: {
        meta: {
          current_page: 1,
        },
      },
      printing:false,
      packges_headers: [
        { text: "Code", value: "code", sortable: false },
        { text: "Pickup City", value: "pickup_city_name", sortable: false },
        { text: "Delivery City", value: "delivery_city_name", sortable: false },
        { text: "COD", value: "cod",align:"right",sortable:false },
        { text: "Total Amount",align:"right", value: "total_amount", sortable: false },
        { text: "Paid Amount", align:"right",value: "paid_amount", sortable: false },
        { text: "Pickup Date", value: "package_date", sortable: false },
        { text: "Created at", value: "created_at", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Current Stage", value: "public_status_text", sortable: false },
        { text: "Action", value: "actions", sortable: false },
      ],
      deleteModal: false,
      code: null,
      stage: null,
      start: null,
      end: null,
      stage_change: null,
      dialog: false,
      stageItems: [
        { id: "1", name: "Order Placed" },
        { id: "2", name: "Processing" },
        { id: "3", name: "Shipped" },
        { id: "4", name: "Delivered" },
      ],
      package_code: null,
      pickup_city: null,
      delivery_city: null,
      weight: null,
      lenght: null,
      height: null,
      width: null,
      descripition: null,
      date: null,
      pacakge_id: null,
      receiver_name: null,
      receiver_contact: null,
      receiver_fax: null,
      receiver_email: null,
      receiver_address: null,
      receiver_landmark: null,
      sender_name: null,
      sender_contact: null,
      sender_fax: null,
      sender_email: null,
      sender_address: null,
      sender_landmark: null,
      page: 1,
      selectedPaymentItem: {
        code: "",
        formatted_total: "",
        hash: "",
        merchant: "",
        sender_email: "",
        sender_phone: "",
        pickup_city_name: "",
      },
    };
  },
  computed: {
    getPayHereUrl() {
      return "https://www.payhere.lk/pay/checkout";
      // return "https://sandbox.payhere.lk/pay/checkout";
    },
    getPagesCount() {
      const count = this.pagination.meta.total / this.pagination.meta.per_page;
      return Math.ceil(count);
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
    this.setToken();
    this.getPackages();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    paginationChanged(page) {
      this.getPackages(page);
    },
    pay(item) {
      this.selectedPaymentItem = item;
      setTimeout(() => {
        this.$refs.paymentForm.submit();
      }, 1000);
    },
    print(item) {
      axios
        .get(this.$hostname + "/api/client/print/" + item.id, {
        responseType: 'blob',
        Accept: 'application/pdf',
      })
        .then((response) => {
          // if (response.data.status == "success") {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/pdf" })
            );
            const link = document.createElement("a");
            console.log(link);
            link.href = url;
            // link.setAttribute("download", "staffs.pdf"); //or any other extension
            link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
          // } else {
          //   alert("Not Authenticated");
          // }
        })
        .catch((error) => {
          console.log(error);
        })
    },
    addPackages() {
      this.$router.push({ name: "AddPackages" });
    },
    setToken() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },
    getPackages(page = 1) {
      axios
        .get(this.$hostname + "/api/client/package/get-paginate?page=" + page)
        .then((response) => {
          this.getCustomerPackages = response.data.package;
          this.pagination = response.data.pagination;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    OpendeleteDailog(id) {
      this.pacakge_id = id;
      this.deleteModal = true;
    },
    deletePackage() {
      axios
        .delete(this.$hostname + "/api/client/package/" + this.pacakge_id)
        .then(() => {
          this.deleteModal = false;
          this.getPackages();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    search() {
      axios
        .get(this.$hostname + "/api/client/package/get-paginate", {
          params: {
            code: this.code,
            public_status: this.stage_change,
            start: this.start,
            end: this.end,
          },
        })
        .then((response) => {
          this.getCustomerPackages = response.data.package;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportExcel() {
      let url = this.$hostname + "/api/client/package/export?";
      if(this.code != null){
        url += 'code='+this.code;
      }
      if(this.stage_change != null){
        url += 'public_status='+this.stage_change;
      }
      if(this.start != null){
        url += 'start='+this.start;
      }
      if(this.end != null){
        url += 'end='+this.end;
      }
      this.printing = true;

      axios
        .get(url,{
            headers:
            {
                'Content-Disposition': "attachment; filename=template.xlsx",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer',
        })
        .then((response) => {
          // if (response.data.status == "success") {
            const url = window.URL.createObjectURL(
              new Blob([response.data])
            );
            const link = document.createElement("a");
            console.log(link);
            link.href = url;
            link.setAttribute("download", "customer_packages.xlsx"); //or any other extension
            // link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
          // } else {
          //   alert("Not Authenticated");
          // }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.printing = false;
        });
    },
    selectStage() {
      let stage_id = this.stage;
      this.stage_change = stage_id;
    },
    Clear() {
      this.code = null;
      this.start = null;
      this.end = null;
      this.status = null;
      (this.stage = null), (this.stage_change = null);
    },
    clearSearch() {
      this.getPackages();
      this.Clear();
    },
    viewPackage(id) {
      this.$router.push({
        name: "ViewPackage",
        params: { id: id },
      });
    },
    EditPackage(id) {
      this.$router.push({
        name: "EditPackages",
        params: { id: id },
      });
    },
  },
};
</script>
<style scoped>
.card__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
