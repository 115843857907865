<template>
  <v-app id="inspire">
    <AppBar> </AppBar>
    <v-container fluid>
      <v-alert type="error" dense v-if="errorAlertText">
        {{ errorAlertText }}
      </v-alert>
      <v-alert type="success" dense v-if="successAlertText">
        {{ successAlertText }}
      </v-alert>
      <v-row>
        <v-col cols="12" class="mb-2">
          <v-btn
            @click="backToList"
            depressed
            class="white--text"
            color="primary"
          >
            <v-icon>mdi-chevron-double-left</v-icon>Back To Dashboard</v-btn
          >
        </v-col>
      </v-row>
      <v-form @submit.prevent lazy-validation autocomplete="off" ref="form">
        <v-card>
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-card elevation="2">
                    <v-card-title>
                      <span>Profile Details</span>
                    </v-card-title>
                    <v-container fluid>
                      <v-row align="center">
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="First name ( required )"
                            v-model="form.first_name"
                          ></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Last name ( required )"
                            v-model="form.last_name"
                            clearable
                          ></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="E-mail ( optional )"
                            v-model="form.email"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            v-model="form.phone"
                            label="Phone number"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            type="password"
                            v-model="form.password"
                            label="Password"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            type="password"
                            v-model="form.password_confirmation"
                            label="Password Confirmation"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-card elevation="2">
                    <v-card-title>
                      <span>Bank Details</span>
                    </v-card-title>
                    <v-container fluid>
                      <v-row align="center">
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Name ( required )"
                            v-model="form.banks[0].name"
                          ></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Bank name ( required )"
                            v-model="form.banks[0].bank"
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Branch name ( required )"
                            v-model="form.banks[0].branch"
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Account number ( required )"
                            v-model="form.banks[0].acc"
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-right mb-2" cols="12" sm="12">
              <v-btn
                depressed
                :disabled="loading"
                @click="updateProfile()"
                color="primary"
                >Update Profile</v-btn
              >
              <v-btn
                color="#ff0000"
                class="ml-4 cancel__btn"
                depressed
                @click="backToList()"
                >Cancel</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-container>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <FooterView />
  </v-app>
</template>

<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import Form from "vform";
import setAuthHeader from "@/utils/setAuthHeader";

export default {
  name: "AddPackages",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      id: null,
      loading: false,
      form: new Form({
        id: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        password: null,
        password_confirmation: null,
        banks: [
          {
            name: null,
            bank: null,
            branch: null,
            acc: null,
          },
        ],
      }),
      errorAlertText: "",
      successAlertText: "",
    };
  },
  created() {
    this.setToken();
  },
  mounted() {
    this.getCurrentUserDetails();
  },
  methods: {
    setToken() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },
    backToList() {
      return this.$router.push({ name: "CustomerDashboard" });
    },
    updateProfile() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        this.form
          .put(this.$hostname + "/api/client/profile/update", this.form)
          .then((response) => {
            this.successAlertText = "";

            let existingUserData = localStorage.getItem("userDetails");
            existingUserData = JSON.parse(existingUserData);
            existingUserData.user.first_name = response.data.user.first_name;
            existingUserData.user.last_name = response.data.user.last_name;
            existingUserData.user.name = response.data.user.name;
            existingUserData.user.email = response.data.user.email;
            existingUserData.user.phone = response.data.user.phone;
            if(response.data.user.banks.length > 0){
              existingUserData.user.banks = response.data.user.banks;
            }
            localStorage.setItem(
              "userDetails",
              JSON.stringify(existingUserData)
            );

            this.successAlertText = response.data.message;
            this.$router.push({ name: "CustomerDashboard" });
          })
          .catch((error) => {
            this.errorAlertText = "";
            this.errorAlertText = error.response.data.message;
            this.scrollToTop();
          })
          .finally(() => {
            this.loading = false;
            this.$validator.reset();
          });
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getCurrentUserDetails() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        this.form.id = user.user.id;
        this.form.first_name = user.user.first_name;
        this.form.last_name = user.user.last_name;
        this.form.email = user.user.email;
        this.form.phone = user.user.phone;
        if(user.user.banks.length > 0){
          this.form.banks = user.user.banks;
        }
      } else {
        this.$router.push({ name: "CustomerDashboard" });
      }
    },
  },
};
</script>
<style scoped>
.cancel__btn {
  color: white;
}

.address_line {
  margin-top: 10px;
  line-height: 5px;
}
</style>
