<template>
  <v-app id="inspire">
    <AppBar />
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="mb-2">
          <v-btn
            @click="goPrevious"
            depressed
            class="white--text"
            color="primary"
          >
            <v-icon>mdi-chevron-double-left</v-icon>Go Back</v-btn
          >
        </v-col>
      </v-row>
      <v-card>
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col cols="12">
                <v-card elevation="2">
                  <v-card-title>
                    <span>Package Details</span>
                  </v-card-title>
                  <v-container fluid>
                    <v-row align="center">
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Tracking Code: {{ this.code }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Barcode: {{ this.barcode }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >PickUp City: {{ this.pickup_city }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Delivery City: {{ this.delivery_city }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Weight (kg) : {{ this.weight }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Length (cm) : {{ this.length }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Height (cm) : {{ this.height }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6"
                        ><span style="color: black"
                          >Width (cm) : {{ this.width }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Date : {{ this.package_date }}</span
                        >
                      </v-col>
                      <v-col
                        v-if="this.description == null"
                        class="d-flex"
                        cols="6"
                        sm="6"
                      >
                        <span style="color: black"
                          >Description : {{ this.description }}</span
                        >
                      </v-col>
                      <v-col v-else class="d-flex" cols="12" sm="12">
                        <span style="color: black"
                          >Description : {{ this.description }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card elevation="2">
                  <v-card-title>
                    <span>Package Timeline</span>
                  </v-card-title>
                  <v-container fluid>
                    <v-row
                      v-for="timeline in package_timeline"
                      :key="timeline.id"
                    >
                      <v-col cols="12" md="12">
                        <div class="stage-warapper">
                          <p class="text--primary">
                            <b
                              >{{ timeline.stage_name }} -
                              {{ timeline.updated_at }}</b
                            >
                            <v-tooltip v-if="timeline.recurring" top>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  color="red"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  class="text-white"
                                >
                                  <v-icon color="red">
                                    mdi-undo-variant
                                  </v-icon>
                                </span>
                              </template>
                              <span
                                >This record has rejected back to this
                                stage</span
                              >
                            </v-tooltip>
                          </p>
                          <p class="text--primary">
                            {{ timeline.public_description }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-if="this.status == 3" >
                      <v-col cols="12" md="12">
                        <span
                          style="color: red"
                          >
                          <b>Pickup request has rejected by Falcon Express.</b><br /> Reason : {{this.reject_reason}}
                          </span
                        >
                         </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6">
            <v-row>
              <v-col cols="12">
                <v-card elevation="2">
                  <v-card-title>
                    <span>Receiver Details</span>
                  </v-card-title>
                  <v-container fluid>
                    <v-row align="center">
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Receiver Name : {{ this.receiver_name }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Receiver Contact : {{ this.receiver_contact }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Receiver fax : {{ this.receiver_fax }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Receiver Email : {{ this.receiver_email }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Delivery Address : {{ this.receiver_address }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Receiver Landmark :
                          {{ this.receiver_landmark }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card elevation="2">
                  <v-card-title>
                    <span>Sender Details</span>
                  </v-card-title>
                  <v-container fluid>
                    <v-row align="center">
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Sender Name : {{ this.sender_name }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Sender Contact : {{ this.sender_contact }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Sender Fax : {{ this.sender_fax }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Sender Email : {{ this.sender_email }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >PickUp Address : {{ this.sender_address }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Sender Landmark : {{ this.sender_landmark }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card elevation="2" style="height: auto">
                  <v-card-title>
                    <span>Payment Details</span>
                  </v-card-title>
                  <v-container fluid>
                    <v-row align="center">
                      <v-col class="d-flex" cols="6" sm="6"
                        ><span style="color: black"
                          >Pay By : {{ this.pay_by_text }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Payment Type: {{ this.payment_type_text }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Total Amount:
                          {{ this.total_amount.toFixed(2) }}</span
                        >
                      </v-col>
                      <v-col class="d-flex" cols="6" sm="6">
                        <span style="color: black"
                          >Paid Amount: {{ this.paid_amount.toFixed(2) }}</span
                        >
                      </v-col>
                      <v-col class="d-flex flex-column" cols="12" sm="12">
                        <span
                          style="color: orange"
                          v-if="!this.courier_confirm && !packageOverlay && (this.status == 1 || this.status == 2)"
                          ><span
                            class="mdi mdi-hand-back-left-off md-48"
                          ></span>
                          Courier is not confirmed the package details
                          yet,Please wait till courier confirmation before
                          submit the payment</span
                        >
                        <span
                          style="color: green"
                          v-if="this.courier_confirm && this.paymentPending"
                          ><span
                            class="mdi mdi-shield-check-outline md-48"
                          ></span>
                          Courier confirmed the details.You can make the payment
                          now</span
                        >
                      </v-col>
                      <v-col
                        v-if="payment_type == 3 && paymentPending"
                        class="d-flex flex-column"
                        cols="12"
                        sm="12"
                      >
                        <div class="bank-wrapper">
                          <h4>Bank Details</h4>
                          <br />
                          <p class="details_line">
                            Name &nbsp;&nbsp;&nbsp;: Falcon Express Ceylon (pvt)
                            ltd
                          </p>
                          <p class="details_line">
                            Acc &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 1069 1402
                            9547
                          </p>
                          <p class="details_line">
                            Bank &nbsp;&nbsp;&nbsp;&nbsp;: Sampath Bank
                          </p>
                          <p class="details_line">Branch : Homagama branch</p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-overlay :value="packageOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <FooterView />
  </v-app>
</template>

<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import axios from "axios";
import setAuthHeader from "@/utils/setAuthHeader";
export default {
  name: "viewPackage",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      id: Number(this.$route.params.id),
      windowWidth: window.innerWidth,
      packageOverlay: false,
      code: null,
      barcode: null,
      pickup_city: null,
      delivery_city: null,
      weight: null,
      length: null,
      height: null,
      width: null,
      description: null,
      pay_by: null,
      payment_type: null,
      package_date: null,
      receiver_name: null,
      receiver_contact: null,
      receiver_fax: null,
      receiver_email: null,
      receiver_address: null,
      receiver_landmark: null,
      sender_name: null,
      sender_contact: null,
      pay_by_text: null,
      payment_type_text: null,
      sender_fax: null,
      sender_email: null,
      sender_address: null,
      sender_landmark: null,
      prevRoute: null,
      courier_confirm: 0,
      total_amount: 0,
      paid_amount: 0,
      status:1,
      reject_reason:"",
      package_timeline: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  created() {
    window.addEventListener("resize", this.onResize);
    this.setToken();
  },
  watch: {
    windowWidth(newW, oldW) {
      console.log("new", newW, " | old", oldW);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!this.$route.params.id) {
      return this.$router.push({ name: "CustomerPackages" });
    }
    this.getPackageForView();
  },
  computed: {
    paymentPending() {
      return Number(this.total_amount) < Number(this.paid_amount);
    },
  },
  methods: {
    goPrevious() {
      return this.$router.push({ name: this.prevRoute.name });
    },
    setToken() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },
    getPackageForView() {
      this.packageOverlay = true;
      axios
        .get(this.$hostname + "/api/client/package/" + this.id)
        .then((response) => {
          console.log(response.data.package);
          this.code = response.data.package.code;
          this.barcode = response.data.package.barcode;
          this.pickup_city = response.data.package.pickup_city_name;
          this.delivery_city = response.data.package.delivery_city_name;
          this.weight = response.data.package.weight;
          this.status = response.data.package.status;
          this.reject_reason = response.data.package.reject_reason;
          this.length = response.data.package.length;
          this.height = response.data.package.height;
          this.width = response.data.package.width;
          this.description = response.data.package.description;
          this.pay_by = response.data.package.pay_by;
          this.pay_by_text = response.data.package.pay_by_text ?? "";
          this.payment_type_text =
            response.data.package.payment_type_text ?? "";
          this.payment_type = response.data.package.payment_type;
          this.package_date = response.data.package.package_date;
          this.courier_confirm = response.data.package.courier_confirm;
          this.receiver_name =
            response.data.package.package_person.receiver_name;
          this.receiver_contact =
            response.data.package.package_person.receiver_contact;
          this.receiver_fax = response.data.package.package_person.receiver_fax;
          this.receiver_email =
            response.data.package.package_person.receiver_email;
          this.receiver_address =
            response.data.package.package_person.receiver_address;
          this.receiver_landmark =
            response.data.package.package_person.receiver_landmark;
          this.sender_name = response.data.package.package_person.sender_name;
          this.sender_contact =
            response.data.package.package_person.sender_contact;
          this.sender_fax = response.data.package.package_person.sender_fax;
          this.sender_email = response.data.package.package_person.sender_email;
          this.sender_address =
            response.data.package.package_person.sender_address;
          this.sender_landmark =
            response.data.package.package_person.sender_landmark;
          this.total_amount = response.data.package.total_amount;
          this.paid_amount = response.data.package.paid_amount;
          this.package_timeline = response.data.package.timeline;
          console.log(response.data.package);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.packageOverlay = false;
        });
    },
  },
};
</script>
<style scoped>
.md-48 {
  font-size: 1.5rem;
}

.details_line {
  line-height: 15px;
}

.bank-wrapper {
  background-color: rgb(180, 180, 180);
  border-radius: 10px;
  padding: 5px 10px;
}
</style>
