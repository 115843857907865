<template>
  <v-app id="inspire">
    <AppBar> </AppBar>
    <v-container fluid>
      <v-alert type="error" dense v-if="errorAlertText">
        {{ errorAlertText }}
      </v-alert>
      <v-alert type="success" dense v-if="successAlertText">
        {{ successAlertText }}
      </v-alert>
      <v-row>
        <v-col cols="12" class="mb-2">
          <v-btn
            @click="backToList"
            depressed
            class="white--text"
            color="primary"
          >
            <v-icon>mdi-chevron-double-left</v-icon>Back To List</v-btn
          >
        </v-col>
      </v-row>
      <v-form @submit.prevent lazy-validation autocomplete="off" ref="form">
        <v-card>
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-card elevation="2">
                    <v-card-title>
                      <span>Package Details</span>
                    </v-card-title>
                    <v-container fluid>
                      <v-row align="center">
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-combobox
                            v-model="pickup_city"
                            autocomplete="nope"
                            :items="pickupCities"
                            item-text="name"
                            item-value="id"
                            label="PickUp City ( required )"
                            solo
                            dense
                            @change="setPickCityId()"
                            :rules="[(v) => !!v || 'Pick up city is required']"
                          ></v-combobox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-combobox
                            v-model="delivery_city"
                            :items="deliveryCities"
                            autocomplete="nope"
                            item-text="name"
                            item-value="id"
                            label="Delivery City ( required )"
                            dense
                            solo
                            :rules="[(v) => !!v || 'Delivery city is required']"
                            @change="setDeliveryCityId()"
                          ></v-combobox>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <label>Select Your Pickup Date</label>
                          <v-text-field
                            label="Date ( required )"
                            type="date"
                            v-model="form.package_date"
                            @input="dateChange"
                            clearable
                            solo
                            :rules="[(v) => !!v || 'Date is required']"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" class="mt-0">
                          <v-checkbox
                            v-model="form.same_day"
                            label="Same day delivery requested"
                            color="blue"
                            :value="1"
                            hide-details
                            @change="calTotal"
                          ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="6" sm="6">
                          <v-text-field
                            step="0.1"
                            min="0"
                            type="number"
                            label="Weight (kg) ( required )"
                            v-model="form.weight"
                            clearable
                            solo
                            @input="calTotal"
                            :rules="[(v) => !!v || 'Weight is required']"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="6" sm="6">
                          <v-text-field
                            step="0.1"
                            min="0"
                            type="number"
                            @input="calTotal"
                            :label="
                              totals.package_type.id == 1
                                ? 'length (cm)'
                                : 'length (cm) ( required )'
                            "
                            v-model="form.length"
                            clearable
                            solo
                            :rules="
                              totals.package_type.id == 1
                                ? []
                                : [(v) => !!v || 'Length is required']
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="6" sm="6">
                          <v-text-field
                            step="0.1"
                            @input="calTotal"
                            min="0"
                            type="number"
                            :label="
                               totals.package_type.id == 1
                                ? 'height (cm)'
                                : 'height (cm) ( required )'
                            "
                            v-model="form.height"
                            clearable
                            solo
                            :rules="
                               totals.package_type.id == 1
                                ? []
                                : [(v) => !!v || 'Height is required']
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="6" sm="6">
                          <v-text-field
                            step="0.1"
                            min="0"
                            @input="calTotal"
                            type="number"
                            :label="
                              totals.package_type.id == 1
                                ? 'width (cm)'
                                : 'width (cm) ( required )'
                            "
                            v-model="form.width"
                            clearable
                            solo
                            :rules="
                              totals.package_type.id == 1
                                ? []
                                : [(v) => !!v || 'Width is required']
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <h4>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{
                                    " Package Estimated Weight for Pricing : " +
                                    totals.total_weight +
                                    "kg "
                                  }}
                                </span>
                              </template>
                              <span
                                >Estimated weight was calculated by considering
                                package dimensions</span
                              >
                            </v-tooltip>
                          </h4>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-textarea
                            v-model="form.description"
                            solo
                            name="input-7-4"
                            label="Items Description ( Optional )"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card elevation="2">
                    <v-card-title>
                      <span>Payment Details</span>
                    </v-card-title>
                    <v-container fluid>
                      <v-row align="center">
                        <v-col class="d-flex" cols="12" sm="6">
                          <v-select
                            v-model="form.pay_by"
                            :items="payBy"
                            label="Pay By"
                            item-value="id"
                            item-text="name"
                            @change="changeValuePayBy()"
                            dense
                            solo
                            :rules="[(v) => !!v || 'Pay By is required']"
                          ></v-select>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="6">
                          <v-select
                            v-model="form.payment_type"
                            :items="paymentTypes"
                            label="Payment Type"
                            item-value="id"
                            item-text="name"
                            @input="calTotal"
                            dense
                            solo
                            :rules="[(v) => !!v || 'Payment Type is required']"
                          ></v-select>
                        </v-col>
                        <v-col
                          v-if="form.pay_by == 3"
                          class="d-flex"
                          cols="12"
                          sm="12"
                        >
                          <v-text-field
                            v-model="form.cod"
                            type="number"
                            @input="calTotal"
                            min="0"
                            label="Item Price (Rs)"
                            clearable
                            solo
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6">
                          <h4>
                            Courier Charges : {{ totals.with_transaction_cost }}
                          </h4>
                        </v-col>
                        <v-col cols="6" sm="6">
                          <h4>Net Total : {{ totals.net_total }}</h4>
                        </v-col>
                        <v-col
                          v-if="form.payment_type == '3'"
                          cols="12"
                          sm="12"
                        >
                          <h4>Bank Details</h4>
                          <p class="address_line">
                            Bank &nbsp;&nbsp;&nbsp;&nbsp;: Sampath Bank
                          </p>
                          <p class="address_line">
                            Acc &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 1069 1402
                            9547
                          </p>
                          <p class="address_line">Branch : Homagama branch</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-card elevation="2">
                    <v-card-title>
                      <span>Receiver Details</span>
                    </v-card-title>
                    <v-container fluid>
                      <v-row align="center">
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Receiver Name ( required )"
                            v-model="form.receiver_name"
                            clearable
                            solo
                            :rules="[(v) => !!v || 'Name is required']"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Receiver Contact ( required )"
                            v-model="form.receiver_contact"
                            clearable
                            solo
                            :rules="[
                              (v) => !!v || 'Contact number is required',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Receiver Fax ( optional )"
                            v-model="form.receiver_fax"
                            clearable
                            solo
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Receiver email ( optional )"
                            v-model="form.receiver_email"
                            clearable
                            solo
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Receiver address ( required )"
                            v-model="form.receiver_address"
                            clearable
                            solo
                            :rules="[(v) => !!v || 'Address is required']"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Receiver Landmark ( optional )"
                            v-model="form.receiver_landmark"
                            clearable
                            solo
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card elevation="2">
                    <v-card-title>
                      <span>Sender Details</span>
                    </v-card-title>
                    <v-container fluid>
                      <v-row align="center">
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Sender Name ( required )"
                            v-model="form.sender_name"
                            clearable
                            solo
                            :rules="[(v) => !!v || 'Name is required']"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Sender Contact ( required )"
                            v-model="form.sender_contact"
                            clearable
                            solo
                            :rules="[
                              (v) => !!v || 'Contact number is required',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Sender Fax ( optional )"
                            v-model="form.sender_fax"
                            clearable
                            solo
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Sender E-mail ( optional )"
                            v-model="form.sender_email"
                            clearable
                            solo
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            v-model="form.sender_address"
                            label="Pick up address ( required )"
                            clearable
                            solo
                            :rules="[(v) => !!v || 'Address is required']"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            v-model="form.sender_landmark"
                            label="Sender Landmark ( optional )"
                            clearable
                            solo
                          ></v-text-field>
                        </v-col>
                        <v-col
                          class="d-flex align-right mb-2"
                          cols="12"
                          sm="12"
                        >
                          <v-btn
                            v-if="forEdit"
                            depressed
                            :disabled="calculating || packageOverlay"
                            @click="updatePackagesData()"
                            color="primary"
                            >Update Package</v-btn
                          >
                          <v-btn
                            v-else
                            depressed
                            :disabled="calculating || packageOverlay"
                            @click="addPackagesData()"
                            color="primary"
                            >Add Package</v-btn
                          >
                          <v-btn
                            color="#ff0000"
                            class="ml-4 cancel__btn"
                            depressed
                            @click="backToList()"
                            >Cancel</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="12">
            <v-row>
               
            </v-row>
          </v-col> -->
          </v-row>
        </v-card>
      </v-form>
      <TodayPackages v-if="!forEdit" ref="todayPackages" class="mt-3" />
    </v-container>

    <v-overlay :value="packageOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <FooterView />
  </v-app>
</template>

<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import axios from "axios";
import setAuthHeader from "@/utils/setAuthHeader";
import Form from "vform";
import TodayPackages from "./TodayPackages.vue";

export default {
  name: "AddPackages",
  components: {
    AppBar,
    FooterView,
    TodayPackages,
  },

  data() {
    return {
      id: null,
      calculating:false,
      totals:{
        total_weight:0,
        package_type:{ id: 1, name: "Document" },
        with_transaction_cost:0,
        net_total:0
      },
      pickupCities: [],
      deliveryCities: [],
      allCities: [],
      is_corporate: 1,
      payBy: [
        {
          id: 1,
          name: "Sender",
        },
        {
          id: 2,
          name: "Receiver",
        },
        {
          id: 3,
          name: "Receiver ( COD )",
        },
      ],
      form: new Form({
        pickup_city_id: "",
        delivery_city_id: "",
        package_date: "",
        weight: "",
        length: "",
        height: "",
        width: "",
        cod: "",
        description: "",
        pay_by: 1,
        payment_type: 1,
        receiver_name: "",
        receiver_contact: "",
        receiver_fax: "",
        receiver_email: "",
        receiver_address: "",
        receiver_landmark: "",
        sender_name: "",
        sender_contact: "",
        sender_fax: "",
        sender_email: "",
        sender_address: "",
        sender_landmark: "",
        same_day: null,
      }),
      pricing: {
        same_day: 0,
        cod_commission: 0,
        card: 0.03,
      },
      pickup_city: null,
      delivery_city: null,
      packageOverlay: false,
      errorAlertText: "",
      successAlertText: "",
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
    this.setToken();
    this.getCity();
    this.getCurrentUserDetails();
  },
  mounted() {
    window.scrollTo(0, 0);
    this.id = this.$route.params?.id;
    if (this.id != null) {
      this.getPackageForEdit();
    }
    this.getPricingSettings();
  },
  computed: {
    forEdit() {
      return this.id != null;
    },
    paymentTypes() {
      let array = [
        {
          id: 1,
          name: "Cash",
        },
       
        {
          id: 3,
          name: "Bank transfer",
        },
      ];
      if (this.form.pay_by == 1) {
        array.push({
          id: 2,
          name: "Card",
        });
      }
      if (this.is_corporate == 1) {
        array.push({
          id: 4,
          name: "Credit",
        });
      }
      return array;
    }
  },
  methods: {
    goPrevious() {
      return this.$router.push({ name: this.prevRoute.name });
    },
    getPricingSettings() {
      axios
        .get(this.$hostname + "/api/get-pricing-settings")
        .then((response) => {
          const data = response.data.data;
          const sameDay = data.filter((obj) => {
            return obj.name == "same_day";
          })[0];
          const cod = data.filter((obj) => {
            return obj.name == "cod_commission";
          })[0];
          const card = data.filter((obj) => {
            return obj.name == "card_payment";
          })[0];
          let obj = {
            same_day:sameDay.current_value / 100,
            cod_commission:cod.current_value / 100,
            card:card.current_value / 100
          }
          this.pricing = obj;
        });
    },
    backToList() {
      return this.$router.push({ name: "CustomerPackages" });
    },
    setToken() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },
    getCity() {
      axios
        .get(this.$hostname + "/api/client/get-cities")
        .then((response) => {
          this.allCities = response.data.data;
          this.setPickupCities();
          this.setDeliveryCities();
          this.setPackageCities();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    calTotal() {
      this.calculating = true;
      const packageData = {
        weight:this.form.weight,
        height:this.form.height,
        length:this.form.length,
        width:this.form.width,
        delivery_city_id:this.form.delivery_city_id,
        pickup_city_id:this.form.pickup_city_id,
        cod:this.form.cod,
        same_day:this.form.same_day == null ? 0 : this.form.same_day,
        payment_type:this.form.payment_type
      }
      
      if(this.id != null){
        packageData.id = this.id;
      }
      axios
        .post(this.$hostname + "/api/client/cal-total",packageData)
        .then((response) => {
          this.totals = response.data;
        })
        .catch((error) => {
          console.log(error);
        }).finally(()=>{
          this.calculating = false;
        });
    },
    setPickupCities() {
      let array = [];
      this.allCities.forEach((item) => {
        if (item.area_type_id < 3) {
          array.push(item);
        }
      });
      this.pickupCities = array;
    },
    setDeliveryCities() {
      let array = [];
      this.allCities.forEach((item) => {
        if (item.area_type_id < 4) {
          array.push(item);
        }
      });
      this.deliveryCities = array;
    },
    setPackageCities() {
      if (
        this.pickup_city == null &&
        this.pickupCities.length > 0 &&
        this.form.pickup_city_id != null
      ) {
        this.pickup_city = this.getRecordById(
          this.pickupCities,
          this.form.pickup_city_id
        )[0];
      }
      if (
        this.delivery_city == null &&
        this.deliveryCities.length > 0 &&
        this.form.delivery_city_id != null
      ) {
        this.delivery_city = this.getRecordById(
          this.deliveryCities,
          this.form.delivery_city_id
        )[0];
      }
    },
    getRecordById(array, id) {
      return array.filter((item) => {
        return item.id == id;
      });
    },
    getPackageForEdit() {
      this.packageOverlay = true;
      axios
        .get(this.$hostname + "/api/client/package/" + this.id)
        .then((response) => {
          this.package_id = response.data.package.id;
          this.form.pickup_city_id = response.data.package.pickup_city_id;
          this.form.delivery_city_id = response.data.package.delivery_city_id;
          this.form.package_date = response.data.package.package_date;
          this.code = response.data.package.code;
          this.form.weight = response.data.package.weight;
          this.form.length = response.data.package.length;
          this.form.height = response.data.package.height;
          this.form.width = response.data.package.weight;
          this.form.description = response.data.package.description;
          this.form.same_day = response.data.package.same_day;
          this.payment_type_text = response.data.package.payment_type_text;
          this.payment_type = response.data.package.payment_type;
          this.form.receiver_name =
            response.data.package.package_person.receiver_name;
          this.form.receiver_contact =
            response.data.package.package_person.receiver_contact;
          this.form.receiver_fax =
            response.data.package.package_person.receiver_fax;
          this.form.receiver_address =
            response.data.package.package_person.receiver_address;
          this.form.receiver_email =
            response.data.package.package_person.receiver_email;
          this.form.receiver_landmark =
            response.data.package.package_person.receiver_landmark;
          this.form.sender_fax =
            response.data.package.package_person.sender_fax;
          this.form.sender_email =
            response.data.package.package_person.sender_email;
          this.form.sender_address =
            response.data.package.package_person.sender_address;
          this.form.sender_landmark =
            response.data.package.package_person.sender_landmark;

          let responsePayBy = response.data.package.pay_by;
          if (responsePayBy == 2 && response.data.package.cod > 0) {
            responsePayBy = 3;
          }
          this.form.pay_by = responsePayBy;
          this.setPackageCities();
          this.changeValuePayBy();
          this.form.payment_type = response.data.package.payment_type;
          this.form.cod = response.data.package.cod ?? 0;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.packageOverlay = false;
        });
    },
    changeValuePayBy() {
      this.form.cod = 0;
      this.form.payment_type = 1;
      this.calTotal();
    },
    addPackagesData() {
      if (this.$refs.form.validate()) {
        this.packageOverlay = true;

        this.form
          .post(this.$hostname + "/api/client/package")
          .then((response) => {
            this.successAlertText = "";
            this.successAlertText = response.data.message;
            this.clearForm();
            // this.scrollToTop();
            // this.$router.push({ name: "CustomerPackages" });
            this.$refs.todayPackages.getPackages();
          })
          .catch((error) => {
            this.errorAlertText = "";
            this.errorAlertText = error.response.data.message;
            this.scrollToTop();
          })
          .finally(() => {
            this.packageOverlay = false;
            // this.$validator.reset();
          });
      }
    },
    updatePackagesData() {
      if (this.$refs.form.validate()) {
        this.packageOverlay = true;

        this.form
          .put(
            this.$hostname + "/api/client/package/" + this.package_id,
            this.form
          )
          .then((response) => {
            this.successAlertText = "";
            this.successAlertText = response.data.message;
            this.clearForm();
            this.scrollToTop();
            this.$router.push({ name: "CustomerPackages" });
          })
          .catch((error) => {
            this.errorAlertText = "";
            this.errorAlertText = error.response.data.message;
            this.scrollToTop();
          })
          .finally(() => {
            this.packageOverlay = false;
            this.$validator.reset();
          });
      }
    },

    clearForm() {
      this.pickup_city = null;
      this.delivery_city = null;
      this.form.pickup_city_id = null;
      this.form.delivery_city_id = null;
      this.form.package_date = null;
      this.form.weight = null;
      this.form.length = null;
      this.form.height = null;
      this.form.width = null;
      this.form.description = null;
      this.form.pay_by = 1;
      this.form.payment_type = 1;
      this.form.receiver_name = null;
      this.form.cod = null;
      this.form.receiver_contact = null;
      this.form.receiver_fax = null;
      this.form.receiver_email = null;
      this.form.receiver_address = null;
      this.form.receiver_landmark = null;
      // this.form.sender_fax = null;
      // this.form.sender_email = null;
      // this.form.sender_landmark = null;
      this.form.same_day = null;
      this.cod = null;
      this.payment_type = null;
      this.calTotal();
    },
    setPickCityId() {
      this.form.pickup_city_id = this.pickup_city.id;
      this.calTotal();
    },
    setDeliveryCityId() {
      this.form.delivery_city_id = this.delivery_city.id;
      this.calTotal();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    dateChange(date){
      const today = new Date();
      const newDate = new Date(date);

// 👇️ Today's date
console.log(today);

console.log(today.toDateString() === newDate.toDateString());
this.calTotal();
    },
    getCurrentUserDetails() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        this.is_corporate = user.user.is_corporate;
        this.form.sender_name = user.user.name;
        this.form.sender_email = user.user.email;
        this.form.sender_address = user.user.address;
        this.form.sender_contact = user.user.phone;
        this.address = user.user.address;
      } else {
        this.$router.push({ name: "LoginScreen" });
      }
    },
  },
};
</script>
<style scoped>
.cancel__btn {
  color: white;
}

.address_line {
  margin-top: 10px;
  line-height: 5px;
}
</style>
