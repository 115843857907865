<template>
  <v-app id="inspire">
    <AppBar NavIcon="NavIcon"> </AppBar>
    <div style="background: #e6ebef; text-align: center">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card elevation="4">
              <v-card-title>
                Your Payments
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-form @submit.prevent lazy-validation ref="payment">
              <v-card elevation="8">
                <v-card-title>
                  <v-col cols="12" md="3" lg="3" xl="3">
                    <v-select
                      v-model="type"
                      :items="paymentTypes"
                      label=" Select Type"
                      @change="changeValuePaymentType()"
                      dense
                      solo
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3">
                    <v-select
                      v-model="status"
                      :items="payBy"
                      label="Select Status"
                      @change="changeValueSatus()"
                      dense
                      solo
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3">
                    <v-text-field
                      label="Date"
                      type="date"
                      clearable
                      solo
                      dense
                      v-model="start"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3">
                    <v-text-field
                      label="Date"
                      type="date"
                      clearable
                      solo
                      dense
                      v-model="end"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="3" sm="3">
                    <v-btn depressed @click="search()" color="primary"
                      >Search</v-btn
                    >
                    <v-btn
                      class="ml-5 white--text"
                      depressed
                      @click="clearSearch()"
                      color="red"
                      >Clear</v-btn
                    >
                  </v-col>
                </v-card-title>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="8">
              <v-data-table
                :headers="payments_headers"
                :items="payments"
                hide-default-footer
              >
                <template v-slot:[`item.status_message`]="{ item }">
                  <v-chip color="green" dark x-small v-if="item.status == '1'">
                    Payment Success
                  </v-chip>
                  <v-chip color="red" dark x-small v-else>
                    Payment Failed
                  </v-chip>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    color="blue"
                    elevation="2"
                    @click="openViewModal(item)"
                    outlined
                    rounded
                    x-small
                  >
                    View
                  </v-btn>
                </template>
              </v-data-table>
              <v-pagination
            v-if="getPagesCount > 1"
              class="mt-4"
              v-model="pagination.meta.current_page"
              :length="getPagesCount"
              :total-visible="7"
              @input="paginationChanged"
              circle
            ></v-pagination>
            </v-card>
          </v-col>
        </v-row>
        <!--view modal open-->
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Payment Details</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <span>Package Code : {{ this.package_code }}</span>
                    </v-col>
                    <v-col cols="12">
                      <span
                        >Package Status :
                        <v-chip
                          color="green"
                          dark
                          x-small
                          v-if="this.status == '1'"
                        >
                          Payment Success
                        </v-chip>
                        <v-chip color="red" dark x-small v-else>
                          Payment Failed
                        </v-chip>
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <span>Paid Amount : {{ this.paid_amount_formatted }}</span>
                    </v-col>
                    <v-col cols="12">
                      <span>Package Status : {{ this.status_message }}</span>
                    </v-col>
                    <v-col cols="12">
                      <span>Date : {{ this.date }}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <!--view modal close-->
      </v-container>
    </div>
    <FooterView />
  </v-app>
</template>

<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import axios from "axios";
import setAuthHeader from "@/utils/setAuthHeader";
export default {
  name: "CustomerPayments",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      payments_headers: [
        { text: "Package Code", value: "package_code" },
        { text: "Type", value: "type_text" },
        { text: "Amount",align:"right", value: "paid_amount_formatted" },
        { text: "Status", align:"center",value: "status_message" },
        { text: "Date", value: "date" },
        { text: "Action", value: "actions" },
      ],
      payments: [],
      pagination:{
        meta:{
          current_page:1
        }
      },
      paymentTypes: ["Cash", "Card", "Bank transfer"],
      payBy: ["Success", "Failed"],
      type: null,
      status: null,
      start: null,
      end: null,

      type_change: null,
      status_change: null,
      dialog: false,
      package_code: null,
      type_text: null,
      paid_amount: null,
      paid_amount_formatted: null,
      status_message: null,
      date: null,
      current_page: 1,
      last_page: 5,
      per_page: 10,
      total: 43,
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
    this.setToken();
    this.getPayments();
  },
    computed:{
    getPagesCount(){
      const count = this.pagination.meta.total / this.pagination.meta.per_page;
      return Math.ceil(count);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    paginationChanged(page){
      this.getPayments(page);
    },
    getPayments(page = 1) {
      axios
        .get(this.$hostname + "/api/client/payment/get-paginate?page="+page)
        .then((response) => {
          this.payments = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setToken() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },
    changeValuePaymentType() {
      if (this.type == "Cash") {
        this.type_change = "1";
      } else if (this.type == "Card") {
        this.type_change = "2";
      } else {
        this.type_change = "3";
      }
    },
    changeValueSatus() {
      if (this.status == "Failed") {
        this.status_change = "0";
      } else {
        this.status_change = "1";
      }
    },
    search() {
      axios
        .get(this.$hostname + "/api/client/payment/get-paginate", {
          params: {
            type: this.type_change,
            status: this.status_change,
            start: this.start,
            end: this.end,
          },
        })
        .then((response) => {
          this.payments = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearVaribales() {
      this.type = null;
      this.status = null;
      this.start = null;
      this.end = null;
      this.type_change = null;
      this.status_change = null;
    },
    clearSearch() {
      this.getPackages();
      this.clearVaribales();
    },

    openViewModal(item) {
      this.dialog = true;
      this.package_code = item.package_code;
      this.status = item.status;
      this.paid_amount_formatted = item.paid_amount_formatted;
      this.status_message = item.status_message;
      this.date = item.date;
    },

    next() {
      axios
        .get(this.$hostname + "/api/client/payment/get-paginate", {
          params: {
            current_page: this.current_page,
          },
        })
        .then((response) => {
          this.payments = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped></style>
