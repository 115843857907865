<template>
  <div>
    <AppBar />
    <div style="background: #e6ebef; text-align: center">
      <v-container>
        <!-- Desktop Card 1 Start -->
        <v-card
          elevation="0"
          style="margin-top: 70px; margin-bottom: 70px; padding-bottom: 20px"
          rounded
          class="rounded-xl"
        >
          <!-- <v-alert type="error" dense v-if="errorAlertText">
            {{ errorAlertText }}
          </v-alert> -->
          <v-row style="padding-top: 10px" align="center">
            <v-col cols="12" md="12">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <v-img
                  lazy-src="@/assets/image/falcon-icon.png"
                  style="margin: 25px auto"
                  max-height="auto"
                  max-width="200px"
                  src="@/assets/image/falcon-icon.png"
                ></v-img>
              </div>

              <p
                style="
                  color: #0674ba;
                  font-family: 'Rubik';
                  font-style: normal;
                  font-size: 38px;
                  text-transform: uppercase;
                "
              >
                Order Tracking
              </p>

              <p
                style="
                  color: black;
                  font-family: 'SF Pro Display';
                  font-style: normal;
                  font-weight: 800;
                  font-size: 11px;
                  letter-spacing: 0.8px;
                  margin-bottom: 30px;
                "
              >
                Please note that these are accurate but not guaranteed
                estimates. <br />
                Delivery date is subject to change without advance notice
              </p>

              <v-col md="6" offset-md="3">
                <v-text-field
               v-on:keypress.enter="handleEnterPress"
                  label="eg : VLKRBEAE11FB"
                  placeholder="eg : VLKRBEAE11FB"
                  solo
                  dense
                  clearable
                  v-model="track_number"
                  class="rubik-fw600-16-grey"
                  style="border-radius: 10px"
                  :rules="[(v) => !!v || 'Please enter your tracking code']"
                ></v-text-field>
                <v-alert type="error" dense v-if="errorAlertText">
                  {{ errorAlertText }}
                </v-alert>
              </v-col>

              <v-btn
              :disabled="tracking"
                @click="trackOrder()"
                color="#0674BA"
                class="white--text rounded-lg"
                x-large
                style="
                  font-family: 'Rubik';
                  font-style: normal;
                  font-size: 14px;
                  text-transform: uppercase;
                  padding-left: 70px;
                  padding-right: 70px;
                "
              >
              <span v-if="tracking"
                  >TRACKING..</span>
                <span v-else
                  >TRACK
                </span>
                
                <span v-if="tracking"
                  >&nbsp;<i class="fa-sharp fa-spin fa-solid fa-arrows-spin"></i
                ></span>
              </v-btn>
              <p
                @click="trackNew()"
                style="
                  color: #0674ba;
                  font-family: 'SF Pro Display';
                  font-style: normal;
                  font-weight: 800;
                  font-size: 12px;
                  letter-spacing: 0.8px;
                  margin-top: 30px;
                  cursor: pointer;
                "
              >
                Track another package?
              </p>
            </v-col>
          </v-row>
          <div v-if="code != null">
            <hr
              style="margin-left: 10%; margin-right: 10%; margin-bottom: 50px"
            />

            <!-- Desktop Start -->
            <p
              style="
                color: black;
                font-family: 'Rubik';
                font-style: normal;
                font-size: 35px;
              "
            >
              Order Status :
              <span style="color: #07a504">{{ this.active_public_stage }}</span>
            </p>
            <!-- Desktop End -->

            <!-- Desktop Start -->
            <p
              style="
                color: black;
                font-family: 'SF Pro Display';
                font-style: normal;
                font-weight: 800;
                font-size: 15px;
                letter-spacing: 0.8px;
                margin-bottom: 30px;
              "
            >
              Estimate Delivery Date : {{ this.estimated_delivery }}
            </p>
            <!-- Desktop End -->

            <!-- Desktop Start -->
            <p
              style="
                color: black;
                font-family: 'Rubik';
                font-style: normal;
                font-size: 15px;
              "
            >
              Order Id : {{ this.code }}
            </p>
            <!-- Desktop End -->

            <!-- Desktop Start -->
            <v-row
              class="desktop-row"
              style="
                padding-top: 10px;
                margin-bottom: 50px;
                margin-left: 10%;
                margin-right: 10%;
              "
              align="center"
            >
              <v-col cols="12" md="12">
                <v-stepper
                  alt-labels
                  flat
                  v-bind:value="1"
                  v-if="status == '1'"
                >
                  <v-stepper-header>
                    <v-stepper-step
                      :complete="orderPlaced.status != null"
                      step="1"
                    >
                      <p
                        style="
                          color: black;
                          font-family: 'SF Pro Display';
                          font-style: normal;
                          font-weight: 800;
                          font-size: 15px;
                          letter-spacing: 0.8px;
                          margin-bottom: 15px;
                          text-align: center;
                        "
                      >
                        {{ orderPlaced.name }}
                      </p>
                      <p
                        style="
                          color: black;
                          font-family: 'Rubik';
                          font-style: normal;
                          font-size: 15px;
                        "
                      >
                        {{ orderPlaced.date }}
                      </p>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                      :complete="processing.status != null"
                      step="2"
                    >
                      <p
                        style="
                          color: black;
                          font-family: 'SF Pro Display';
                          font-style: normal;
                          font-weight: 800;
                          font-size: 15px;
                          letter-spacing: 0.8px;
                          margin-bottom: 15px;
                          text-align: center;
                        "
                      >
                        {{ processing.name }}
                      </p>
                      <p
                        style="
                          color: black;
                          font-family: 'Rubik';
                          font-style: normal;
                          font-size: 15px;
                        "
                      >
                        {{ processing.date }}
                      </p>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="shipped.status != null" step="3">
                      <p
                        style="
                          color: black;
                          font-family: 'SF Pro Display';
                          font-style: normal;
                          font-weight: 800;
                          font-size: 15px;
                          letter-spacing: 0.8px;
                          margin-bottom: 15px;
                          text-align: center;
                        "
                      >
                        {{ shipped.name }}
                      </p>
                      <p
                        style="
                          color: black;
                          font-family: 'Rubik';
                          font-style: normal;
                          font-size: 15px;
                        "
                      >
                        {{ shipped.date }}
                      </p>
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step
                      :complete="delivered.status != null"
                      step="4"
                    >
                      <p
                        style="
                          color: black;
                          font-family: 'SF Pro Display';
                          font-style: normal;
                          font-weight: 800;
                          font-size: 15px;
                          letter-spacing: 0.8px;
                          margin-bottom: 15px;
                          text-align: center;
                        "
                      >
                        {{ delivered.name }}
                      </p>
                      <p
                        style="
                          color: black;
                          font-family: 'Rubik';
                          font-style: normal;
                          font-size: 15px;
                        "
                      >
                        {{ delivered.date }}
                      </p>
                    </v-stepper-step>
                  </v-stepper-header>
                </v-stepper>
              </v-col>
            </v-row>
            <!-- Desktop End -->

            <!-- Desktop Start -->
            <v-row
              class="desktop-row"
              style="margin-left: 10%; margin-right: 10%"
              align="left"
            >
              <v-col cols="12" md="12" align="left">
                <v-timeline dense>
                  <v-timeline-item
                    v-for="(data, index) in timeline"
                    :key="index"
                    fill-dot
                    color="#07A504"
                    icon="mdi-check"
                  >
                    <v-card
                      class="elevation-2"
                      style="border-radius: 16px; background: #f7f7f7"
                    >
                      <p
                        style="
                          color: #212529;
                          font-family: 'SF Pro Display';
                          font-style: normal;
                          font-weight: 800;
                          font-size: 12px;
                          letter-spacing: 0.8px;
                          padding-left: 15px;
                          padding-bottom: 15px;
                          padding-top: 15px;
                        "
                      >
                        {{ data.updated_at }}
                      </p>
                      <p
                        style="
                          color: #212529;
                          font-family: 'SF-Pro-Display-Semibold';
                          font-style: normal;
                          font-weight: 800;
                          font-size: 12px;
                          letter-spacing: 0.8px;
                          padding-left: 15px;
                          padding-bottom: 15px;
                          padding-top: 0px;
                        "
                      >
                        {{ data.public_description }}
                      </p>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
            </v-row>
            <!-- Desktop End -->
          </div>
        </v-card>
        <!-- Desktop Card 1 End -->
      </v-container>
    </div>

    <FooterView />
  </div>
</template>

<script>
import AppBar from "./AppBar.vue";
import FooterView from "./FooterView.vue";
import setAuthHeader from "@/utils/setAuthHeader";
import axios from "axios";
export default {
  name: "OrderTrack",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      track_code: this.$route.params.track_code,
      windowWidth: window.innerWidth,
      track_number: null,
      estimated_delivery: null,
      public_status: [],
      timeline: [],
      tracking: false,
      code: null,
      placed_date: null,
      orderPlaced: {},
      processing: {},
      shipped: {},
      delivered: {},
      errorAlertText: "",
      active_public_stage: null,
      status: null,
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
    this.setToken();
  },
  watch: {
    windowWidth(newW, oldW) {
      console.log("new", newW, " | old", oldW);
      //  return this.windowWidth;
      // this.onResize();
    },
  },
  mounted() {
    console.log(this.$route.params.track_code);
    window.scrollTo(0, 0);
    if (this.track_code) {
      this.track_number = this.track_code;
      this.trackOrder();
    }
  },
  methods: {
    handleEnterPress(){
      this.trackOrder();
    },
    trackNew() {
      this.track_code = null;
      this.track_number = null;
      this.estimated_delivery = null;
      this.public_status = [];
      this.timeline = [];
      this.code = null;
      this.placed_date = null;
      this.orderPlaced = {};
      this.processing = {};
      this.shipped = {};
      this.delivered = {};
      this.errorAlertText = "";
      this.active_public_stage = null;
      this.status = null;
      this.errorAlertText = "";
    },
    setToken() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },
    trackOrder() {
      if (this.track_number) {
        this.tracking = true;
        axios
          .get(
            this.$hostname + "/api/client/package/track/" + this.track_number
          )
          .then((response) => {
            if (response.data.package) {
              this.errorAlertText = "";
            }
            this.estimated_delivery = response.data.package.estimated_delivery;
            this.code = response.data.package.code;
            this.placed_date = response.data.package.placed_date;
            this.timeline = response.data.package.timeline;
            this.public_status = response.data.package.public_status;
            this.orderPlaced = this.public_status[0];
            this.processing = this.public_status[1];
            this.shipped = this.public_status[2];
            this.delivered = this.public_status[3];
            this.active_public_stage =
              response.data.package.active_public_stage;
            this.status = response.data.package.status;
          })
          .catch((error) => {
            this.errorAlertText = "Invalid tracking code";
            this.scrollToTop();
            console.log(error);
          })
          .finally(() => {
            this.tracking = false;
          });
      } else {
        this.errorAlertText = "Enter your tracking code";
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style>
@font-face {
  font-family: Rubik;
  src: url(../../public/fonts/Rubik/Rubik-SemiBold.ttf);
}
@font-face {
  font-family: Rubik-Regular;
  src: url(../../public/fonts/Rubik/Rubik-Regular.ttf);
}
@font-face {
  font-family: SF Pro Display;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Thin.otf);
}
@font-face {
  font-family: SF-Pro-Display-Semibold;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Semibold.otf);
}

@media (max-width: 767px) {
  .v-application--is-ltr .v-stepper__label {
    display: block !important;
  }

  .desktop-row .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 72px !important;
  }
  .desktop-row .v-stepper--alt-labels .v-stepper__header {
    flex-direction: column;
  }
  .desktop-row .v-stepper--alt-labels .v-stepper__header .v-divider {
    margin: 35px -13px 0 !important;
    margin: 0 !important;
  }
  .desktop-row .v-timeline--dense .v-timeline-item__body {
    max-width: 100% !important;
  }
  .desktop-row .v-timeline-item__divider {
    display: none !important;
  }
}
</style>
