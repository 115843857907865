<template>
    <div>
        <!-- Desktop Screen Start -->
        <v-system-bar class="white--text d-none d-sm-flex" color="#0674BA" height="40">
            <v-container>
                <div class="d-flex align-center">
                    <p class="mt-5" style="font-family: 'SF Pro Display'; font-size: 13px; letter-spacing: 1px; cursor: pointer" @click="redirectTel()">
                        <v-icon color="white">mdi-phone-in-talk</v-icon>&nbsp; Call Us : 0112 878524
                    </p>
                    &emsp; &emsp;
                    <p class="mt-5" style="font-family: 'SF Pro Display'; font-size: 13px; letter-spacing: 1px; cursor: pointer" @click="redirectMailTo()">
                        <v-icon color="white">mdi-email</v-icon>&nbsp; Email Us : info@falcon.lk
                    </p>

                    <v-spacer></v-spacer>

                    <p class="mt-5" style="font-family: 'SF Pro Display'; font-size: 13px; letter-spacing: 1px">
                        <v-icon color="white">mdi-clock-time-five</v-icon>&nbsp; Opening Hours : Mon-Fri : 8am - 5pm
                    </p>
                    &emsp; &emsp;
                    <v-btn icon @click="redirectSocialMedia(1)">
                        <v-icon color="white">mdi-facebook</v-icon>
                    </v-btn>
                    <v-btn icon @click="redirectSocialMedia(2)">
                        <v-icon color="white">mdi-instagram</v-icon>
                    </v-btn>
                    <v-btn icon @click="redirectSocialMedia(3)">
                        <v-icon color="white">mdi-linkedin</v-icon>
                    </v-btn>
                    <v-btn icon @click="redirectSocialMedia(4)">
                        <v-icon color="white">mdi-whatsapp</v-icon>
                    </v-btn>
                </div>
            </v-container>
            <!--navigation drawer for Desktop open-->
            <v-navigation-drawer v-model="drawer" fixed temporary>
                <v-sheet style="background-color: blue" color="white" class="pa-8 overflow-hidden" absolute temporary>
                    <v-img
                        lazy-src="@/assets/image/falcon-icon.png"
                        max-height="auto"
                        max-width="120px"
                        style="background-position: center; cursor: pointer; margin-right: 25px"
                        src="@/assets/image/falcon-icon.png"
                    ></v-img>
                </v-sheet>

                <v-divider></v-divider>

                <v-list>
                    <v-list-item :to="{ name: 'CustomerDashboard' }">
                        <v-list-item-icon>
                            <v-icon large color="purple darken-2"> mdi-dialpad </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title style="font-weight: bold; font-size: 15px">Dashboard</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item :to="{ name: 'CustomerPackages' }">
                        <v-list-item-icon>
                            <v-icon large color="blue-grey darken-2"> mdi-package-variant-closed </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title style="font-weight: bold; font-size: 15px">Packages</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item :to="{ name: 'CustomerPayments' }">
                        <v-list-item-icon>
                            <v-icon large color="teal darken-2"> mdi-cash </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title style="font-weight: bold; font-size: 15px">Payments</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="logOut()">
                        <v-list-item-icon>
                            <v-icon large color="teal darken-2"> mdi-logout </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title style="font-weight: bold; font-size: 15px">Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
            <!--navigation drawer Desktop close-->
        </v-system-bar>
        <!-- Desktop Screen End -->
        <!-- Mobile Screen Start -->
        <v-system-bar v-if="windowWidth <= 600" class="white--text" color="#0674BA" height="40"
            ><v-container>
                <div class="d-flex align-center">
                    <p class="mt-5" style="font-family: 'SF Pro Display'; font-size: 11px; letter-spacing: 1px; cursor: pointer" @click="redirectTel()">
                        <v-icon color="white">mdi-phone-in-talk</v-icon>&nbsp; 0112 878524
                    </p>
                    <v-spacer></v-spacer>
                    <p class="mt-5" style="font-family: 'SF Pro Display'; font-size: 11px; letter-spacing: 1px; cursor: pointer" @click="redirectMailTo()">
                        <v-icon color="white">mdi-email</v-icon>&nbsp; info@falcon.lk
                    </p>
                </div>
            </v-container>
        </v-system-bar>
        <!-- Mobile Screen End -->

        <!-- Desktop Screen Start -->
        <v-app-bar v-if="windowWidth >= 600" height="70">
            <!-- <v-app-bar-nav-icon
        v-if="user_has && NavIcon"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon> -->
            <!-- <p
        v-if="user_has"
        class="rubik-fw600-15 mt-5 ml-8"
        style="cursor: pointer"
        @click="redirectToHomePage()"
        :style="{
          cursor: 'pointer',
          color: $route.name == 'home' ? '#0674BA' : '#000000',
        }"
      >
        HOME
      </p> -->
            <v-container>
                <div class="d-flex align-center">
                    <v-img
                        lazy-src="@/assets/image/falcon-icon.png"
                        max-height="auto"
                        max-width="120px"
                        style="background-position: center; cursor: pointer; margin-right: 25px"
                        src="@/assets/image/falcon-icon.png"
                        @click="redirectToHomePage()"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <p
                        class="rubik-fw600-15 mt-5"
                        style="cursor: pointer"
                        @click="redirectToHomePage()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'home' ? '#0674BA' : '#000000',
                        }"
                    >
                        HOME
                    </p>
                    &emsp; &emsp;
                    <!-- <p
            class="rubik-fw600-15 mt-5"
            @click="redirectUserDashboardPage()"
            :style="{
              cursor: 'pointer',
              color: $route.name == 'AboutUs' ? '#0674BA' : '#000000',
            }"
          >
            DASHBOARD
          </p> -->
                    <p
                        v-if="$route.meta.hideNavbar"
                        class="rubik-fw600-15 mt-5"
                        @click="redirectToAboutUsPage()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'AboutUs' ? '#0674BA' : '#000000',
                        }"
                    >
                        ABOUT US
                    </p>
                    <p
                        v-if="user_has && !$route.meta.hideNavbar"
                        class="rubik-fw600-15 mt-5"
                        style="cursor: pointer"
                        @click="redirectToDashboard()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'CustomerDashboard' ? '#0674BA' : '#000000',
                        }"
                    >
                        DASHBOARD
                    </p>
                    &emsp; &emsp;
                    <v-menu nudge-top open-on-hover close-delay="300" bottom transition="slide-y-transition" content-class="my-menu" tile>
                        <template v-slot:activator="{ on, attrs }">
                            <p v-if="$route.meta.hideNavbar" class="rubik-fw600-15 mt-5" v-bind="attrs" v-on="on" style="cursor: pointer">SERVICES</p>
                            <p
                                v-if="user_has && !$route.meta.hideNavbar"
                                class="rubik-fw600-15 mt-5"
                                @click="redirectToUserPackages()"
                                :style="{
                                    cursor: 'pointer',
                                    color: $route.name == 'CustomerPackages' ? '#0674BA' : '#000000',
                                }"
                            >
                                PACKAGES
                            </p>
                        </template>

                        <v-list dense>
                            <v-list-item-group v-model="selectedItem" color="primary">
                                <v-list-item class="listItem" v-for="(item, index) in servicesList" :key="index">
                                    <v-list-item-content @click="redirectToServicePage(item.title)">
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                    <!-- <p class="rubik-fw600-15 mt-5">
        SERVICES
      </p> -->
                    &emsp; &emsp;
                    <p
                        v-if="$route.meta.hideNavbar"
                        class="rubik-fw600-15 mt-5"
                        style="cursor: pointer"
                        @click="redirectToBranchNetworkPage()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'BranchNetwork' ? '#0674BA' : '#000000',
                        }"
                    >
                        BRANCH NETWORK
                    </p>
                    <p
                        v-if="user_has && !$route.meta.hideNavbar"
                        class="rubik-fw600-15 mt-5"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'CustomerPayments' ? '#0674BA' : '#000000',
                        }"
                        @click="redirectToUserPaymenents()"
                    >
                        PAYMENTS
                    </p>
                    &emsp; &emsp;
                    <p
                        v-if="$route.meta.hideNavbar"
                        class="rubik-fw600-15 mt-5"
                        @click="redirectToContactUsPage()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'ContactUs' ? '#0674BA' : '#000000',
                        }"
                    >
                        CONTACT US
                    </p>
                    <!-- <p
            class="rubik-fw600-15 mt-5"
            @click="redirectToUserPaymentPage()"
            :style="{
              cursor: 'pointer',
              color: $route.name == 'ContactUs' ? '#0674BA' : '#000000',
            }"
          >
            PAYMENTS
          </p> -->
                    &emsp; &emsp;
                    <p
                        v-if="$route.meta.hideNavbar"
                        class="rubik-fw600-15 mt-5"
                        @click="redirectToFAQPage()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'FAQDetails' ? '#0674BA' : '#000000',
                        }"
                    >
                        FAQ
                    </p>

                    &emsp; &emsp;
                    <!-- <v-chip  v-if="user_has && !$route.meta.hideNavbar" class="ma-2" color="primary" label>
            <v-icon> mdi-account-circle-outline </v-icon>
            {{ this.user_name }}
          </v-chip> -->

                    <v-btn v-if="user_has" color="#F7941D" @click="redirectCustomerDashboard()" class="white--text" depressed>
                        {{ this.user_name }} &nbsp;<v-icon color="white">mdi-account</v-icon>
                    </v-btn>
                    <v-btn v-else color="#F7941D" class="white--text" depressed @click="redirectToLoginPage()">
                        CUSTOMER LOGIN &nbsp;<v-icon color="white">mdi-account</v-icon>
                    </v-btn>
                    <v-btn v-if="user_has && !$route.meta.hideNavbar" color="#ff0000" @click="logOut()" class="white--text ml-2" depressed>
                        LOGOUT &nbsp;<v-icon color="white">mdi-logout</v-icon>
                    </v-btn>
                </div>
            </v-container>
        </v-app-bar>
        <!-- Desktop Screen End -->

        <!-- Mobile Screen Start -->
        <v-app-bar v-if="windowWidth <= 600" height="70">
            <!-- <v-app-bar-nav-icon
        v-if="user_has && NavIcon"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon> -->
            <v-container>
                <div class="d-flex align-center">
                    <v-img
                        lazy-src="@/assets/image/falcon-icon.png"
                        max-height="auto"
                        max-width="120px"
                        style="cursor: pointer"
                        src="@/assets/image/falcon-icon.png"
                        @click="redirectToHomePage()"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-app-bar-nav-icon
                        @click.stop="drawerDialog = !drawerDialog"
                        style="background: #0674ba; border-radius: 8px; color: white"
                    ></v-app-bar-nav-icon>
                </div>
            </v-container>
        </v-app-bar>
        <!--navigation drawer for mobile open-->
        <v-navigation-drawer v-if="windowWidth <= 600" v-model="drawer" fixed temporary>
            <v-sheet style="background-color: blue" color="white" class="pa-8 overflow-hidden" absolute temporary>
                <v-img
                    lazy-src="@/assets/image/falcon-icon.png"
                    max-height="auto"
                    max-width="120px"
                    style="background-position: center; cursor: pointer"
                    src="@/assets/image/falcon-icon.png"
                ></v-img>
            </v-sheet>

            <v-divider></v-divider>

            <v-list>
                <v-list-item to="/customer/dashboard" link>
                    <v-list-item-icon>
                        <v-icon large color="purple darken-2"> mdi-dialpad </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title style="font-weight: bold; font-size: 15px">Dashboard</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item to="/customer/packages" link>
                    <v-list-item-icon>
                        <v-icon large color="blue-grey darken-2"> mdi-call-split </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title style="font-weight: bold; font-size: 15px">Packages</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item to="/customer/payments" link>
                    <v-list-item-icon>
                        <v-icon large color="teal darken-2"> mdi-email </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title style="font-weight: bold; font-size: 15px">payments</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="logOut()">
                    <v-list-item-icon>
                        <v-icon large color="teal darken-2"> mdi-logout </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title style="font-weight: bold; font-size: 15px">Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <!--navigation drawer mobile close-->
        <!-- Mobile Screen End -->
        <v-navigation-drawer v-model="drawerDialog" absolute left temporary width="85%">
            <v-row no-gutters>
                <v-col cols="12">
                    <v-img
                        lazy-src="@/assets/image/falcon-icon.png"
                        max-height="auto"
                        max-width="160px"
                        style="cursor: pointer; margin: 25px auto"
                        src="@/assets/image/falcon-icon.png"
                        @click="redirectToHomePage()"
                    ></v-img>
                </v-col>
                <v-col cols="12" class="mt-2">
                    <p
                        v-if="$route.meta.hideNavbar"
                        class="rubik-fw600-15 text-center"
                        @click="redirectToOrderTrack()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'OrderTrack' ? '#0674BA' : '#000000',
                        }"
                    >
                        ORDER TRACK
                    </p>
                    <p
                        class="rubik-fw600-15 mt-5 text-center"
                        style="cursor: pointer"
                        @click="redirectToHomePage()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'home' ? '#0674BA' : '#000000',
                        }"
                    >
                        HOME
                    </p>
                </v-col>
                <v-col cols="12">
                    <p
                        v-if="$route.meta.hideNavbar"
                        class="rubik-fw600-15 text-center"
                        @click="redirectToAboutUsPage()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'AboutUs' ? '#0674BA' : '#000000',
                        }"
                    >
                        ABOUT US
                    </p>
                    <p
                        v-if="user_has && !$route.meta.hideNavbar"
                        class="rubik-fw600-15 text-center"
                        style="cursor: pointer"
                        @click="redirectToDashboard()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'CustomerDashboard' ? '#0674BA' : '#000000',
                        }"
                    >
                        DASHBOARD
                    </p>
                </v-col>
                <v-col cols="12">
                    <p
                        v-if="$route.meta.hideNavbar"
                        class="rubik-fw600-15 text-center"
                        @click="redirectToServicePage1()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'DocumentPackageDelivery' ? '#0674BA' : '#000000',
                        }"
                    >
                        SERVICES
                    </p>
                    <p
                        v-if="user_has && !$route.meta.hideNavbar"
                        class="rubik-fw600-15 text-center"
                        @click="redirectToUserPackages()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'CustomerPackages' ? '#0674BA' : '#000000',
                        }"
                    >
                        PACKAGES
                    </p>
                </v-col>
                <v-col cols="12">
                    <p
                        v-if="user_has && !$route.meta.hideNavbar"
                        class="rubik-fw600-15 text-center"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'CustomerPayments' ? '#0674BA' : '#000000',
                        }"
                        @click="redirectToUserPaymenents()"
                    >
                        PAYMENTS
                    </p>
                    <p
                        v-if="$route.meta.hideNavbar"
                        class="rubik-fw600-15 text-center"
                        @click="redirectToBranchNetworkPage()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'BranchNetwork' ? '#0674BA' : '#000000',
                        }"
                    >
                        BRANCH NETWORK
                    </p>
                </v-col>
                <v-col cols="12">
                    <p
                        v-if="$route.meta.hideNavbar"
                        class="rubik-fw600-15 text-center"
                        @click="redirectToContactUsPage()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'ContactUs' ? '#0674BA' : '#000000',
                        }"
                    >
                        CONTACT US
                    </p>
                </v-col>
                <v-col cols="12" class="mb-2">
                    <p
                        v-if="$route.meta.hideNavbar"
                        class="rubik-fw600-15 text-center"
                        @click="redirectToFAQPage()"
                        :style="{
                            cursor: 'pointer',
                            color: $route.name == 'FAQDetails' ? '#0674BA' : '#000000',
                        }"
                    >
                        FAQ
                    </p>
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-btn v-if="user_has" x-large color="#F7941D" class="white--text" depressed @click="redirectCustomerDashboard()">
                        {{ this.user_name }} &nbsp;<v-icon color="white">mdi-account</v-icon>
                    </v-btn>
                    <v-btn v-else x-large color="#F7941D" class="white--text" depressed @click="redirectToLoginPage()">
                        CUSTOMER LOGIN &nbsp;<v-icon color="white">mdi-account</v-icon>
                    </v-btn>
                    <v-btn v-if="user_has && !$route.meta.hideNavbar" color="#ff0000" @click="logOut()" class="white--text mt-3" depressed x-large>
                        LOGOUT &nbsp;<v-icon color="white">mdi-logout</v-icon>
                    </v-btn>
                    <!-- <v-chip v-if="user_has && !$route.meta.hideNavbar" class="ma-2 text-center" color="primary" label>
                        <v-icon> mdi-account-circle-outline </v-icon>
                        {{ this.user_name }}
                    </v-chip> -->
                </v-col>
                <hr style="width: 80%; margin-left: 35px; margin-top: 25px; margin-bottom: 25px" />
                <v-col cols="12" class="text-center">
                    <v-avatar size="40" class="mr-3 ml-3">
                        <v-img
                            lazy-src="@/assets/image/Home/Icon/facebook-blue.png"
                            max-height="auto"
                            max-width="100%"
                            src="@/assets/image/Home/Icon/facebook-blue.png"
                            @click="redirectSocialMedia(1)"
                            style="cursor: pointer"
                        ></v-img>
                    </v-avatar>
                    <v-avatar size="40" class="mr-3">
                        <v-img
                            lazy-src="@/assets/image/Home/Icon/insta-blue.png"
                            max-height="auto"
                            max-width="100%"
                            src="@/assets/image/Home/Icon/insta-blue.png"
                            @click="redirectSocialMedia(2)"
                            style="cursor: pointer"
                        ></v-img>
                    </v-avatar>
                    <v-avatar size="40" class="mr-3">
                        <v-img
                            lazy-src="@/assets/image/Home/Icon/linked-blue.png"
                            max-height="auto"
                            max-width="100%"
                            src="@/assets/image/Home/Icon/linked-blue.png"
                            @click="redirectSocialMedia(3)"
                            style="cursor: pointer"
                        ></v-img>
                    </v-avatar>
                    <v-avatar size="40" class="mr-3">
                        <v-img
                            lazy-src="@/assets/image/Home/Icon/youtube-blue.png"
                            max-height="auto"
                            max-width="100%"
                            src="@/assets/image/Home/Icon/youtube-blue.png"
                            @click="redirectSocialMedia(4)"
                            style="cursor: pointer"
                        ></v-img>
                    </v-avatar>
                </v-col>
                <v-col>
                    <p class="text-center mt-2" style="font-size: 12px">
                        Falcon Express Delivery © 2022. <br />
                        All Rights Reserved
                    </p>
                </v-col>
            </v-row>
        </v-navigation-drawer>
        <!-- <v-main class="grey lighten-2">
            <v-container fluid>
                <router-view></router-view>
            </v-container>
        </v-main> -->
    </div>
</template>

<script>
import social_links from '@/config.js'
export default {
    // props: ["NavIcon"],
    name: "AppBar",

    data() {
        return {
            windowWidth: window.innerWidth,
            selectedItem: "",
            group: "",
            drawerDialog: false,
            servicesList: [
                { title: "Documents & Packages Delivery" },
                { title: "Same day Pick up & Delivery Services" },
                { title: "Management of Mail Room" },
                { title: "Cash on Delivery (COD)" },
                { title: "Confidential Documents Delivery" },
                { title: "Confidential Parcel Delivery" },
            ],
            role: null,
            drawer: false,
            user_has: null,
            user_no: null,
            user_name: null,
        };
    },
    created() {
        window.addEventListener("resize", this.onResize);
        this.checkLogging();
    },
    mounted() {},
    methods: {
        redirectToServicePage(title) {
            switch (title) {
                case "Documents & Packages Delivery":
                    this.$router.push({ name: "DocumentPackageDelivery" });
                    break;
                case "Same day Pick up & Delivery Services":
                    this.$router.push({ name: "SamedayPickupDeliveryServices" });
                    break;
                case "Management of Mail Room":
                    this.$router.push({ name: "ManagementofMailRoom" });
                    break;
                case "Cash on Delivery (COD)":
                    this.$router.push({ name: "CashOnDelivery" });
                    break;
                case "Confidential Documents Delivery":
                    this.$router.push({ name: "ConfidentialDocumentsDelivery" });
                    break;
                case "Confidential Parcel Delivery":
                    this.$router.push({ name: "ConfidentialParcelDelivery" });
                    break;

                default:
                    break;
            }
        },

        redirectToHomePage() {
            this.$router.push({ name: "home" });
        },
        redirectToLoginPage() {
            this.$router.push({ name: "LoginScreen" });
        },

        redirectToContactUsPage() {
            this.$router.push({ name: "ContactUs" });
        },

        redirectToBranchNetworkPage() {
            this.$router.push({ name: "BranchNetwork" });
        },

        redirectToAboutUsPage() {
            this.$router.push({ name: "AboutUs" });
        },
        redirectToFAQPage() {
            this.$router.push({ name: "FAQDetails" });
        },
        redirectToOrderTrack() {
            this.$router.push({ name: "OrderTrack" });
        },

        redirectToServicePage1() {
            this.$router.push({ name: "DocumentPackageDelivery" });
        },

        redirectToUserPackages() {
            this.$router.push({ name: "CustomerPackages" });
        },
        redirectToUserPaymenents() {
            this.$router.push({ name: "CustomerPayments" });
        },
        redirectToDashboard() {
            this.$router.push({ name: "CustomerDashboard" });
        },

        redirectToHome() {
            window.location.href = "/";
        },
        onResize(e) {
            this.windowWidth = window.innerWidth;
            console.log(e);
        },
        redirectSocialMedia(number) {
            switch (number) {
                case 1:
                    window.open(social_links.facebook, "_blank");
                    break;
                case 2:
                    window.open("https://www.instagram.com/?hl=en", "_blank");
                    break;
                case 3:
                    window.open("https://lk.linkedin.com/", "_blank");
                    break;
                case 4:
                    window.open("https://wa.me/+94772576600/", "_blank");
                    break;

                default:
                    break;
            }
        },
        redirectMailTo() {
            window.location.href = "mailto:info@falcon.lk";
        },
        redirectTel() {
            window.location.href = "tel:0112878524";
        },

        checkLogging() {
            let user = localStorage.getItem("userDetails");
            user = JSON.parse(user);
            if (user) {
                this.user_name = user.user.first_name;
                this.user_has = user;
            } else {
                this.user_name = '';
                this.user_no = user;
                console.log("no user");
            }
        },

        logOut() {
            let user = localStorage.getItem("userDetails");
            user = JSON.parse(user);
            if (user) {
                localStorage.removeItem("userDetails");
                this.$router.push({ name: "home" });
            } else {
                this.$router.push({ name: "LoginScreen" });
            }
        },
        redirectCustomerDashboard() {
            this.$router.push({ name: "CustomerDashboard" });
        },
    },
};
</script>

<style scoped>
.rubik-fw600-15 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.my-menu {
    margin-top: 30px;
    contain: initial;
    overflow: visible;
}
.my-menu::before {
    position: absolute;
    content: "";
    top: 0;
    right: 90%;
    left: 2%;
    transform: translateY(-80%);
    width: 10px;
    height: 13px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 13px solid #fff;
}
.listItem:hover {
    background-color: #0674ba;
    color: white !important;
}
</style>
