import { render, staticRenderFns } from "./DownloadsDesktop.vue?vue&type=template&id=e8e8f842&scoped=true&"
import script from "./DownloadsDesktop.vue?vue&type=script&lang=js&"
export * from "./DownloadsDesktop.vue?vue&type=script&lang=js&"
import style0 from "./DownloadsDesktop.vue?vue&type=style&index=0&id=e8e8f842&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8e8f842",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCard,VCardTitle,VCol,VIcon,VRow,VSheet})
