import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import PublicHome from "./components/PublicHome.vue";
import AdminDashboard from "./components/AdminDashboard.vue";
import DocumentPackageDelivery from "./components/services/DocumentPackageDelivery.vue";
import SamedayPickupDeliveryServices from "./components/services/SamedayPickupDeliveryServices.vue";
import ManagementofMailRoom from "./components/services/ManagementofMailRoom.vue";
import CashOnDelivery from "./components/services/CashOnDelivery.vue";
import ConfidentialDocumentsDelivery from "./components/services/ConfidentialDocumentsDelivery.vue";
import ConfidentialParcelDelivery from "./components/services/ConfidentialParcelDelivery.vue";
import ContactUs from "./components/ContactUs.vue";
import CustomerProfile from "./components/customer/CustomerProfile.vue";
import BranchNetwork from "./components/BranchNetwork.vue";
import AboutUs from "./components/AboutUs.vue";
import FAQDetails from "./components/FAQDetails.vue";
import OrderTrack from "./components/OrderTrack.vue";
import LoginScreen from "./components/LoginScreen.vue";
import RegisterScreen from "./components/RegisterScreen.vue";
import PasswordReset from "./components/PasswordReset.vue";
import NewPassword from "./components/NewPassword.vue";
import CustomerDashboard from "./components/customer/Dashboard.vue";
import CustomerPackages from "./components/customer/Packages.vue";
import CustomerPayments from "./components/customer/Payment.vue";
import AddPackages from "./components/customer/AddPackages.vue";
import ViewPackage from "./components/customer/ViewPackage.vue";
import TermAndCondition from "./components/TermAndCondition.vue";
import PrivacyAndPolicy from "./components/Privacy.vue";

Vue.use(VueRouter);
Vue.prototype.$hostname = process.env.VUE_APP_AXIOS

// 
// const isuserLoggedIn = false;

const router = new VueRouter({
  mode: 'history',
  routes: [{
      path: '/',
      component: PublicHome,
      name: 'home',
      meta: {
        hideNavbar: true,
       }
    },
    {
      path: '/login',
      component: LoginScreen,
      name: 'LoginScreen'
    },
    {
      path: '/register',
      component: RegisterScreen,
      name: 'RegisterScreen'
    },
    {
      path: '/password-reset',
      component: PasswordReset,
      name: 'PasswordReset'
    },
    {
      path: '/new-password',
      component: NewPassword,
      name: 'NewPassword'
    },
    {
      path: '/services/document-package-delivery',
      component: DocumentPackageDelivery,
      name: 'DocumentPackageDelivery',
      meta: {
        hideNavbar: true,
       }
    },
    {
      path: '/services/same-day-pickup-delivery-services',
      component: SamedayPickupDeliveryServices,
      name: 'SamedayPickupDeliveryServices',
      meta: {
        hideNavbar: true,
       }
    },
    {
      path: '/services/management-of-mailRoom',
      component: ManagementofMailRoom,
      name: 'ManagementofMailRoom',
      meta: {
        hideNavbar: true,
       }
    },
    {
      path: '/services/cash-on-delivery',
      component: CashOnDelivery,
      name: 'CashOnDelivery',
      meta: {
        hideNavbar: true,
       }
    },
    {
      path: '/services/confidential-documents-delivery',
      component: ConfidentialDocumentsDelivery,
      name: 'ConfidentialDocumentsDelivery',
      meta: {
        hideNavbar: true,
       }
    },
    {
      path: '/services/confidential-parcel-delivery',
      component: ConfidentialParcelDelivery,
      name: 'ConfidentialParcelDelivery',
      meta: {
        hideNavbar: true,
       }
    },
    {
      path: '/contact-us',
      component: ContactUs,
      name: 'ContactUs',
      meta: {
        hideNavbar: true,
       }
    },
    {
      path: '/branch-network',
      component: BranchNetwork,
      name: 'BranchNetwork',
      meta: {
        hideNavbar: true,
       }
    },
    {
      path: '/about-us',
      component: AboutUs,
      name: 'AboutUs',
      meta: {
        hideNavbar: true,
       }
    },
    {
      path: '/privacy-and-policy',
      component: PrivacyAndPolicy,
      name: 'Privacy',
      meta: {
        hideNavbar: true,
       }
    },
    {
      path: '/term-and-condition',
      component: TermAndCondition,
      name: 'TermAndCondition',
      meta: {
        hideNavbar: true,
       }
    },
    {
      path: '/faq',
      component: FAQDetails,
      name: 'FAQDetails',
      meta: {
        hideNavbar: true,
       }
    },
    {
      path: '/order-track',
      component: OrderTrack,
      name: 'OrderTrack',
      meta: {
        hideNavbar: true,
       }
    },
    {
      path: '/dashboard',
      component: AdminDashboard,
      name: 'dashboard'
    },
    {
      path: '/customer/dashboard',
      component: CustomerDashboard,
      name: 'CustomerDashboard',
      meta: {
        needsAuth: true
      }
    },
    {
      path: '/customer/packages',
      component: CustomerPackages,
      name: 'CustomerPackages',
      meta: {
        needsAuth: true
      }
    },
    {
      path: '/customer/profile',
      component: CustomerProfile,
      name: 'CustomerProfile',
      meta: {
        needsAuth: true
      }
    },
    {
      path: '/customer/payments',
      component: CustomerPayments,
      name: 'CustomerPayments',
      meta: {
        needsAuth: true
      }
    },
    {
      path: '/add/customer/packages',
      component: AddPackages,
      name: 'AddPackages',
      meta: {
        needsAuth: true
      }
    },
    {
      path: '/edit/customer/packages',
      component: AddPackages,
      name: 'EditPackages',
      meta: {
        needsAuth: true
      }
    },
    {
      path: '/view/customer/packages',
      component: ViewPackage,
      name: 'ViewPackage',
      meta: {
        needsAuth: true
      }
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.meta.needsAuth) {
    let user = localStorage.getItem("userDetails");
    user = JSON.parse(user);
    if (user) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});


Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

// router.beforeResolve((to, from, next) => {
//   if (to.meta.needsAuth) {
//     if (isuserLoggedIn) {
//       next();
//     } else {
//       next('/login');
//     }
//   } else {
//     next();
//   }
// })