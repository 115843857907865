var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12"}},[_c('h4',[_vm._v("Recent packages")]),_c('v-card',{attrs:{"elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.packges_headers,"items":_vm.getCustomerPackages,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == '0')?_c('v-chip',{attrs:{"color":"red","dark":"","x-small":""}},[_vm._v(" Cancelled ")]):(item.status == '1')?_c('v-chip',{attrs:{"color":"green","dark":"","x-small":""}},[_vm._v(" Active ")]):(item.status == '2')?_c('v-chip',{attrs:{"color":"yellow","dark":"","x-small":""}},[_vm._v(" Pending ")]):(item.status == '3')?_c('v-chip',{attrs:{"color":"red","dark":"","x-small":""}},[_vm._v(" Rejected ")]):_c('v-chip',{attrs:{"color":"brown","dark":"","x-small":""}},[_vm._v(" unknown ")])]}},{key:"item.public_status_text",fn:function(ref){
var item = ref.item;
return [(item.public_status_text == 'Order Placed')?_c('v-chip',{attrs:{"color":"ash","dark":"","x-small":""}},[_vm._v(" Order Placed ")]):(item.public_status_text == 'Processing')?_c('v-chip',{attrs:{"color":"yellow","dark":"","x-small":""}},[_vm._v(" Processing ")]):(item.public_status_text == 'Shipped')?_c('v-chip',{attrs:{"color":"blue","dark":"","x-small":""}},[_vm._v(" Shipped ")]):(item.public_status_text == 'Delivered')?_c('v-chip',{attrs:{"color":"green","dark":"","x-small":""}},[_vm._v(" Delivered ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.viewPackage(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-eye")]),_vm._v(" View ")],1)],1),(item.status != 3)?_c('v-list-item',{on:{"click":function($event){return _vm.print(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-3"},[_vm._v(" mdi-cloud-print ")]),_vm._v(" Print ")],1)],1):_vm._e()],1)],1)]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"250px"},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Cancel this order ?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.deleteModal = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deletePackage()}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }