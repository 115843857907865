import { render, staticRenderFns } from "./FooterView.vue?vue&type=template&id=0ced354a&scoped=true&"
import script from "./FooterView.vue?vue&type=script&lang=js&"
export * from "./FooterView.vue?vue&type=script&lang=js&"
import style0 from "./FooterView.vue?vue&type=style&index=0&id=0ced354a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ced354a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAvatar,VCol,VContainer,VIcon,VImg,VRow,VSheet})
